<template>
	<div id="editPage" class="container">
		<h1>
			AESC Profile
		</h1>
		<b-message title="Edit Personal Information" size="is-medium" :closable="false">
			<div id="editForm" class="columns">
				<div class="column is-10 is-offset-1">
					<img id="personalPhoto" :src="$APIURL + contact.PhotoSrc" style="margin: 0 auto; display: block; max-height: 150px;" />
					<br />
					<p class="label-edit">Photo</p>
					<b-upload accept=".jpg,.jpeg,.png,"
							  class="spacing"
							  v-model="Photo">
						<a class="button blue-button" icon="fa-upload">Upload Image</a>
						<span style="margin-left: 7px;margin-top:5px;" class="has-text-weight-bold" v-if="Photo">
							{{ Photo.name }}
						</span>
					</b-upload>

					<p class="label-edit">Prefix</p>
					<b-select expanded
							  placeholder="Select a Prefix"
							  class="spacing"
							  v-model="contact.Salutation">
						<option value="Dr.">
							Dr.
						</option>
						<option value="Mr.">
							Mr.
						</option>
						<option value="Mrs.">
							Mrs.
						</option>
						<option value="Ms.">
							Ms.
						</option>
						<option value="Prof.">
							Prof.
						</option>
						<option value="Prof Dr.">
							Prof Dr.
						</option>
					</b-select>
					<p class="label-edit">First Name</p>
					<b-input id="FirstName"
							 v-model="contact.FirstName"
							 class="spacing"
							 placeholder="e.g. Tina"
							 expanded>
					</b-input>
					<p class="label-edit">Last Name</p>
					<b-input id="LastName"
							 v-model="contact.LastName"
							 class="spacing"
							 placeholder="e.g. Fey">
					</b-input>
					<p class="label-edit">Title</p>
					<b-input id="Title"
							 v-model="contact.Title"
							 class="spacing"
							 placeholder="Title">
					</b-input>
					<p class="label-edit">Industries</p>
					<b-taginput id="industries"
								class="spacing"
								v-model="contact.Industries"
								autocomplete
								expanded
								type="is-primary"
								:allow-new="false"
								:data="filteredIndustries"
								:open-on-focus="openOnFocus"
								@typing="filterIndustries"
								placeholder="Add Industry">
						<template slot-scope="props">
							{{props.option}}
						</template>
					</b-taginput>
					<p class="label-edit">Services</p>
					<b-taginput id="services"
								class="spacing"
								v-model="contact.Services"
								autocomplete
								type="is-primary"
								:allow-new="false"
								:data="filteredServices"
								:open-on-focus="openOnFocus"
								@typing="filterServices"
								placeholder="Add Service">
						<template slot-scope="props">
							{{props.option}}
						</template>
					</b-taginput>
					<p class="label-edit">Functions</p>
					<b-taginput id="functions"
								class="spacing"
								v-model="contact.Functions"
								autocomplete
								type="is-primary"
								:allow-new="false"
								:data="filteredFunctions"
								open-on-focus
								@typing="filterFunctions"
								placeholder="Add Function">
						<template slot-scope="props">
							{{props.option}}
						</template>
					</b-taginput>
					<br />
					<p class="label-edit">Bio</p>
					<wysiwyg id="wysiwig"
							 v-model="contact.Bio__c" />
				</div>

			</div>
			<div class="editbuttons columns">
				<div class="column">
					<b-button type="button"
							  class="is-pulled-right"
							  :class="{'is-loading': loading}"
							  icon-left="fa fa-check"
							  @click="updateUser">
						Save
					</b-button>
				</div>
				<div class="column">
					<b-button type="button"
							  icon-left="fa fa-times"
							  @click="cancel">
						Cancel
					</b-button>
				</div>
			</div>
		</b-message>
	</div>
</template>

<script>
	import axios from "axios";
	export default {
		name: "EditProfileForm",
		props: {
			contact: {}
		},
		data: function () {
			return {
				functions: [],
				industries: [],
				services: [],
				filteredFunctions: this.functions,
				filteredIndustries: this.industries,
				filteredServices: this.services,
				error: '',
				loading: false,
				openOnFocus: true,
				Photo: File
			};
		},
		async created() {
			await axios
				.get("api/functions/tags")
				.then(resp => {
					this.functions = resp.data;
				})
				.catch(err => {
					this.error = err;
				});
			await axios
				.get("api/industries/tags")
				.then(resp => {
					this.industries = resp.data;
				})
				.catch(err => {
					this.error = err;
				});
			await axios
				.get("api/services/tags")
				.then(resp => {
					this.services = resp.data;
				})
				.catch(err => {
					this.error = err;
				});
			this.autoServices = this.contact.Services;
			this.autoIndustries = this.contact.Industries;
			this.autoFunctions = this.contact.Functions;
			this.filteredFunctions = this.functions;
			this.filteredIndustries = this.industries;
			this.filteredServices = this.services;

		},
		methods: {
			cancel: function () {
				this.$parent.edit = false;
			},
			updateUser: async function () {
				this.loading = true;
				this.contact.Name = this.contact.FirstName + " " + this.contact.LastName;

				if (this.contact.Functions != null && this.contact.Functions.length > 0) {
					this.contact.Functional_Specialties__c = this.contact.Functions.join(';');
				}

				if (this.contact.Industries != null && this.contact.Industries.length > 0) {
					this.contact.Industry_Specialties__c = this.contact.Industries.join(';');
				} else {
					this.contact.Industry_Specialties__c = '';
				}

				if (this.contact.Services != null && this.contact.Services.length > 0) {
					this.contact.Services__c = this.contact.Services.join(';');
				} else {
					this.contact.Services__c = "";
				}

				await axios.put("api/contacts/" + this.contact.Id, this.contact)
					.then(async resp => {
						if (this.Photo != undefined && this.Photo.name) {
							var formData = new FormData();
							formData.append('file', this.Photo);
							axios.post("api/image/upload/" + this.contact.Id, formData, { headers: { "Content-Type": 'multipart/form-data' } })
								.then(async resp => {
									this.loading = false;
									if (resp.data.Result == "Fail") {
										this.$buefy.toast.open({
											message: "An error occurred uploading the photo.",
											type: "is-danger",
											duration: 8000
										})
									} else if (resp.data.Result == "Success") {
										this.$buefy.toast.open({
											message: "Photo uploaded successfully.",
											type: "is-success",
											duration: 8000
										})
									}
									await this.$parent.storeContact();
									this.$parent.edit = false;
									delete this.Photo;
									this.Photo = undefined;
								}).catch(async err => {
									this.loading = false;
									console.log(err);
									await this.$parent.storeContact();
									this.$parent.edit = false;
									delete this.Photo;
									this.Photo = undefined;
								});
						} else {
							await this.$parent.storeContact();
							this.$parent.edit = false;
						}
					})
					.catch(err => {
						this.loading = false;
						console.log(err);
						this.$buefy.toast.open({
							message: "An unhandled exception occurred.",
							type: 'is-danger',
							duration: 8000
						});
						this.$parent.edit = false;
					});

			},
			filterIndustries(text) {
				this.filteredIndustries = this.industries.filter((option) => {
					return option
						.toString()
						.toLowerCase()
						.indexOf(text.toLowerCase()) >= 0;
				})
			},
			filterFunctions(text) {
				this.filteredFunctions = this.functions.filter((option) => {
					if (option) {
						return option
							.toString()
							.toLowerCase()
							.indexOf(text.toLowerCase()) >= 0;
					}
				})
			},
			filterServices(text) {
				this.filteredServices = this.services.filter((option) => {
					if (option) {
						return option
							.toString()
							.toLowerCase()
							.indexOf(text.toLowerCase()) >= 0;
					}
				})
			},
		},
	}
</script>

<style>
	@import '~vue-wysiwyg/dist/vueWysiwyg.css';

	#wysiwig {
		background-color: white !important;
	}

		#wysiwig .editr--toolbar {
			background-color: white !important;
		}

	#editHead {
		margin: 30px;
	}

	#editPage .message {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	#editPage .message-header {
		height: 65px;
		background-color: rgb(57, 96, 154) !important;
	}

		#editPage .message-header .title {
			color: white;
		}

	.spacing {
		margin-bottom: 10px;
	}

	.label-edit {
		font-size: 20px;
		font-weight: bold;
	}
</style>
