<template>
	<div class="section">
		<div class="container has-text-centered" id="aescFooter">
			<div class="columns">
				<div class="column has-text-left">
					<h4 class="has-lato">Sign Up for Our Newsletter</h4>
					<br />
					<a target="_blank" href="/subscribe">
						<button type="button" id="subscribe"><h4 class="arial">SUBSCRIBE</h4></button>
					</a>
				</div>
				<div class="column has-text-left left-border">
					<h4 class="has-lato">Executive Career Support</h4>
					<br />
					<a
						target="_blank"
						href="https://www.bluesteps.com/partner/association-of-executive-search-consultants">
						<button type="button" id="subscribe"><h4 class="arial">SUBMIT YOUR RESUME</h4></button>
					</a>
				</div>
				<div class="column has-text-left left-border">
					<h4 class="has-lato">Join the Conversation</h4>
					<br />
					<div class="columns is-vcentered is-flex">
						<div class="column is-paddingless logo-maxwidth">
							<span class="logo">
								<a href="https://www.linkedin.com/company/aesc" target="_blank">
									<img
										alt="linkedin"
										style="max-width: 25px !important; max-height: 35px !important"
										src="@/assets/logos/linkedInIcon2017.png" />
								</a>
							</span>
						</div>
						<div class="column is-paddingless logo-maxwidth">
							<span class="logo">
								<a href="http://twitter.com/TheAESC" target="_blank">
									<img
										alt="twitter"
										style="max-width: 25px !important; max-height: 35px !important"
										src="@/assets/logos/twitterIcon2017.png" />
								</a>
							</span>
						</div>
						<div class="column is-paddingless logo-maxwidth">
							<span class="logo">
								<a
									href="https://www.youtube.com/user/AESCExecutiveSearch/?sub_confirmation=1"
									target="_blank">
									<img
										alt="facebook"
										style="max-width: 25px !important; max-height: 35px !important"
										src="@/assets/logos/youtubeIcon2019.png" />
								</a>
							</span>
						</div>
						<div class="column is-paddingless logo-maxwidth">
							<span class="logo">
								<a
									href="https://www.forbes.com/sites/forbeshumanresourcescouncil/people/karengreenbaum"
									target="_blank">
									<img
										id="forbes"
										style="max-width: 50px !important; max-height: 60px !important"
										alt="forbes"
										src="@/assets/logos/forbesIcon2019.png" />
								</a>
							</span>
						</div>
					</div>
				</div>
			</div>
			<br />
			<br />
			<div class="columns has-text-left">
				<div class="column" id="small">
					<p>
						Association of Executive Search and Leadership Consultants |
						<a target="_blank" href="/contact-us/aesc-staff-directory">Contact Us</a>
						|
						<a target="_blank" href="/about-us/privacy-policy">Privacy Policy</a>
						|
						<a target="_blank" href="/about-us/terms-and-conditions">Terms and Conditions</a>
						| Copyright © 2020
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "AescFooter",
		data: function () {
			return {
				dev: true
			};
		}
	};
</script>

<style>
	.arial {
		font-family: Arial, sans-serif !important;
	}

	#aescFooter {
		color: white;
		background-color: #12609d;
		/* padding: 20px; */
		font-family: Arial, sans-serif;
	}

	#subscribe {
		padding: 10px 20px;
		border: 1px solid #fff;
		text-transform: uppercase;
		text-decoration: none;
		color: #fff;
		background-color: #12609d;
		cursor: pointer;
	}

	#subscribe:hover {
		padding: 10px 20px;
		border: 1px solid lightgrey;
		text-transform: uppercase;
		text-decoration: none;
		color: lightgrey;
		background-color: #12609d;
		cursor: pointer;
	}

	/*#subscribe {
				border: solid;
				border-color: white;
				border-width: .5px;
				background-color: #12609d;
				color: white;
				cursor: pointer;
				padding-left: 20px;
				padding-right: 20px;
				padding-top: 10px;
				padding-bottom: 10px;
			}*/

	#aescFooter img {
		max-width: 30px;
	}

	#aescFooter a {
		color: white;
	}

	#aescFooter a:hover {
		color: white;
		border-bottom: solid;
		border-color: white;
		border-width: 1px;
	}

	#small p {
		font-size: 12px;
	}

	.logo {
		padding-left: 5px;
		padding-right: 5px;
		max-height: 60px;
	}

	.logo a:hover {
		border-bottom: none !important;
	}
	.logo-maxwidth {
		max-width: 40px;
	}

	@media (min-width: 769px) {
		.left-border {
			border-left: solid 1px white;
			padding-left: 50px !important;
		}
	}
</style>
