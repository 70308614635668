<template>
	<div class="modal-card">
		<div class="modal-card-body">
			<div class="columns">
				<div class="column">
					<p><b>Request:</b> {{ request }}</p>
					<b-field label="Details of Change:">
						<b-input type="textarea" v-model="details"></b-input>
					</b-field>
					<b-field label="Reason for Change:">
						<b-input type="textarea" v-model="reason"></b-input>
					</b-field>
				</div>
			</div>
		</div>
		<div class="modal-card-foot">
			<b-button @click="$parent.close()">Cancel</b-button>
			<b-button class="is-primary" @click="requestChange">Send Request</b-button>
		</div>
	</div>
</template>

<script>
    export default {
        props: {
            request: String
        },
        data() {
            return {
                details: "",
                reason: "",

            }
        },
        methods: {
            async requestChange() {
                await this.$http.post("api/contacts/request", { request: this.request, details: this.details, reason: this.reason })
                    .then(async resp => {
                        this.$buefy.toast.open({
                            message: resp.data,
                            duration: 8000
                        });
                        this.$parent.close();
                    }).catch(err => {
                        console.log(err);
                    })
            }
        }
    }
</script>
