<template>
	<div id="profile" v-if="contact">
		<displayProfile v-show="!edit" :contact="contact" :office="office" :unsubUrl="unsubUrl" />
		<editProfileForm v-show="edit" :contact="JSON.parse(JSON.stringify(contact))" />
	</div>
</template>

<script>
    import displayProfile from "@/components/profile/displayProfile.vue";
    import editProfileForm from "@/components/profile/editProfileForm.vue";

    export default {
        name: "Profile",
        components: {
            displayProfile,
            editProfileForm
        },
        data: function () {
            return {
                edit: false,
                contact: {},
                office: {},
                unsubUrl: ""
            };
        },
        created: async function () {
            this.edit = false;
            await this.storeContact();
        },
        methods: {
            async storeContact() {
                var login = this.$store.getters["security/login"];

                await this.$http.get("api/contacts/" + login.user.Id).then(async resp => {
                    if (resp.data.Functions == null && resp.data.Functional_Specialties__c == null) {
                        resp.data.Functions = [];
                    }

                    if (resp.data.Services == null && resp.data.Services__c == null) {
                        resp.data.Services = [];
                    }

                    if (resp.data.Industries == null && resp.data.Industries__c == null) {
                        resp.data.Industries = [];
                    }

                    this.contact = resp.data;

                    await this.$http
                        .get("api/accounts/" + this.contact.AccountId)
                        .then(resp => {
                            this.office = resp.data;
                        })
                        .catch(err => {
                            console.log(err);
                            this.$buefy.toast.open({
                                message: "An unhandled exception happened.",
                                type: "is-danger",
                                duration: 8000
                            });
                        });

                    await this.$http.get("api/tasks/getUnsubUrl/" + this.contact.Email)
                        .then(resp => {
                            this.unsubUrl = resp.data;
                        }).catch(err => {
                            console.log(err);
                            this.$buefy.toast.open({
                                message: "An unhandled exception happened.",
                                type: "is-danger",
                                duration: 8000
                            })
                        })

                }).catch(err => {
                    console.log(err);
                });
            }
        }
    };
</script>

<style>
</style>
