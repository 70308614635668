<template>
	<div class="section">
		<div class="container">
			<h1>User Admin</h1>
			<br />
			<div class="columns message" style="padding: 20px;">
				<div class="column is-4">
					<p class="breathe">In the input below, search for a user by typing in the user's Email or Name.</p>
					<br />
					<b-input v-model="search" placeholder="Enter your search here"></b-input>
					<br />
					<b-button class="button is-primary" :class="{'is-loading': loading}" @click="searchForContacts">Search</b-button>
					<br />
				</div>
				<div class="column">
					<div v-show="searched">
						<b-table :data="contacts"
								 hoverable
								 mobile-cards
								 paginated
								 :loading="loading"
								 :per-page="10"
								 pagination-simple>
							<template slot-scope="props">
								<b-table-column label="Edit" width="30">
									<a @click="editUser(props.row)"><i class="far fa-edit"></i></a>
								</b-table-column>
								<b-table-column field="FirstName" label="Name" sortable>
									<router-link :to="{ path: '/directory/profile/' + props.row.Id}" target="_blank">
										{{ props.row.FirstName + ' ' + props.row.LastName }}
									</router-link>
								</b-table-column>
								<b-table-column field="Email" label="Email" sortable width="50">
									<a href="mailTo:props.row.Email">{{ props.row.Email }}</a>
								</b-table-column>
								<b-table-column label="Send Welcome Email">
									<b-button class="button is-primary" @click="sendWelcome(props.row.Email)">Send Email</b-button>
								</b-table-column>
							</template>
						</b-table>
					</div>
				</div>
			</div>
			<br />
			<h1 class="breathe">Sync Directory</h1>
			<br />
			<div class="columns message">
				<div class="column breathe">
					<b-button @click="sync" class="blue-button" :class="{'is-loading': syncLoading}">Start Sync</b-button>
					<br /><br />
					<div class="breathe" v-show="syncResponse && syncResponse.Errors && syncResponse.Errors.length > 0">
						<b-table :data="syncResponse.Errors"
								 hoverable
								 mobile-cards
								 paginated
								 :loading="syncLoading"
								 :per-page="10"
								 pagination-simple>
							<template slot-scope="props">
								<b-table-column label="Errors">
									{{props.row}}
								</b-table-column>
							</template>
						</b-table>
						<br />
					</div>
					<div class="breathe" v-show="syncResponse && syncResponse.Contacts && syncResponse.Contacts.length > 0">
						<b-table :data="syncResponse.Contacts"
								 hoverable
								 mobile-cards
								 paginated
								 :loading="syncLoading"
								 :per-page="10"
								 pagination-simple>
							<template slot-scope="props">
								<b-table-column label="Contacts">
									{{props.row}}
								</b-table-column>
							</template>
						</b-table>
						<br />
					</div>
					<div class="breathe" v-show="syncResponse && syncResponse.Accounts && syncResponse.Accounts.length > 0">
						<b-table :data="syncResponse.Accounts"
								 hoverable
								 mobile-cards
								 paginated
								 :loading="syncLoading"
								 :per-page="10"
								 pagination-simple>
							<template slot-scope="props">
								<b-table-column label="Accounts">
									{{props.row}}
								</b-table-column>
							</template>
						</b-table>
						<br />
					</div>
					<div class="breathe" v-show="syncResponse && syncResponse.Offices && syncResponse.Offices.length > 0">
						<b-table :data="syncResponse.Offices"
								 hoverable
								 mobile-cards
								 paginated
								 :loading="syncLoading"
								 :per-page="10"
								 pagination-simple>
							<template slot-scope="props">
								<b-table-column label="Member Offices.length > 0">
									{{props.row}}
								</b-table-column>
							</template>
						</b-table>
						<br />
					</div>

					<div class="breathe" v-show="syncResponse && syncResponse.LeadIndustries && syncResponse.LeadIndustries.length > 0">
						<b-table :data="syncResponse.LeadIndustries"
								 hoverable
								 mobile-cards
								 paginated
								 :loading="syncLoading"
								 :per-page="10"
								 pagination-simple>
							<template slot-scope="props">
								<b-table-column label="Lead Industries">
									{{props.row}}
								</b-table-column>
							</template>
						</b-table>
						<br />
					</div>
					<div class="breathe" v-show="syncResponse && syncResponse.Services.length > 0">
						<b-table :data="syncResponse.Services"
								 hoverable
								 mobile-cards
								 paginated
								 :loading="syncLoading"
								 :per-page="10"
								 pagination-simple>
							<template slot-scope="props">
								<b-table-column label="Services">
									{{props.row}}
								</b-table-column>
							</template>
						</b-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import editUser from "@/components/profile/editUser.vue";
    export default {
        name: "userAdmin",
        data() {
            return {
                searched: false,
                search: '',
                contacts: [],
                selected: {},
                Photo: null,
                loading: false,
                syncLoading: false,
                syncResponse: {}
            }
        },
        created() {
            this.syncResponse.LeadIndustries = [];
            this.syncResponse.Errors = [];
            this.syncResponse.Contacts = [];
            this.syncResponse.Accounts = [];
            this.syncResponse.Services = [];
            this.syncResponse.Offices = [];
        },
        methods: {
            async sync() {
                this.syncLoading = true;
                await this.$http.get("api/tasks/refresh/directory")
                    .then(resp => {
                        this.syncResponse = resp.data;
                        this.syncLoading = false;
                    }).catch(err => {
                        this.syncLoading = false;
                    })
            },
            async sendWelcome(Email) {
                this.loading = true;
                await this.$http.get("api/contacts/sendWelcome/" + Email)
                    .then(resp => {
                        if (resp.data) {
                            this.$buefy.toast.open({ message: "Successfully sent welcome email.", type: "is-success" });
                        } else {
                            this.$buefy.toast.open({ message: "Failed to send welcome email.", type: "is-danger" });
                        }
                        this.loading = false;
                    }).catch(err => {
                        console.log(err);
                        this.searched = false;
                        this.loading = false;
                    })
            },
            goToProfile(Id) {
                this.$router.push('/directory/profile/' + Id)
            },
            async searchForContacts() {
                if (this.search) {
                    this.loading = true;
                    await this.$http.get("api/contacts/search/" + this.search)
                        .then(resp => {
                            this.contacts = resp.data;
                            this.searched = true;
                            this.loading = false;
                        }).catch(err => {
                            console.log(err);
                            this.searched = false;
                            this.loading = false;
                        })
                }
                else {
                    this.$buefy.toast.open("Please enter a search.");
                }
            },
            editUser(contact) {
                this.$buefy.modal.open({
                    component: editUser,
                    width: 900,
                    props: {
                        User: contact
                    },
                    events: {
                        close() { this.searchForContacts(); }
                    }
                });
            }
        }
    }
</script>
