<template>
	<div class="container">
		<br />
		<b-message>
			<p class="title is-4 aesc-title">
				Enter your new Password
			</p>
			<b-field label="E-mail Address *">
				<b-input id="userEmail"
						 type="email"
						 v-model="form.Email">
				</b-input>
			</b-field>
			<b-field label="New Password *">
				<b-input id="newPassword"
						 type="password"
						 v-model="form.NewPassword">
				</b-input>
			</b-field>
			<b-field label="Confirm Password *">
				<b-input id="confirmPassword"
						 type="password"
						 v-model="form.confirmPassword">
				</b-input>
			</b-field>
			<b-button class="red-button"
					  :class="{'is-loading': loading}"
					  @click="checkPasswords">
				Submit
			</b-button>
		</b-message>
	</div>
</template>

<script>
    import axios from "axios";
    export default {
        name: "resetPassword",
        props: {
            code: String
        },
        data() {
            return {
                form: {},
                err: '',
                loading: false
            }
        },
        methods: {
            checkPasswords() {
                if (this.form.NewPassword == this.form.confirmPassword) {
                    this.resetPassword();
                } else {
                    this.$buefy.toast.open({
                        duration: 8000,
                        message: `Passwords Do not Match`,
                        position: 'is-top',
                        type: 'is-danger'
                    })
                }
            },
            async resetPassword() {
                this.loading = true;
                await axios
                    .post("api/security/resetPassword", { Code: this.code, Email: this.form.Email, NewPassword: this.form.NewPassword })
                    .then(resp => {
                        this.loading = false;
                        if (resp.data == "Redirect Dev") {
                            window.location.href = '/temp-login';
                            this.$buefy.toast.open({
                                message: "Successfully reset password.",
                                position: 'is-top',
                                duration: 8000
                            })
                        } else if (resp.data == "Redirect Regular Login") {
                            window.location.href = '/login';
                            this.$buefy.toast.open({
                                message: "Successfully reset password.",
                                position: 'is-top',
                                duration: 8000
                            })
                        } else {
                            this.$buefy.toast.open({
                                message: resp.data,
                                position: 'is-top',
                                duration: 8000
                            })
                        }
                    })
                    .catch(err => {
                        this.loading = false;
                        this.$buefy.toast.open({
                            duration: 8000,
                            message: err,
                            position: 'is-top',
                            type: 'is-danger'
                        })
                    });
            }
        }
    }
</script>

<style>
</style>
