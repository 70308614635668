<template>
    <div id="directory">
        <div style="background-color: #1F1F1F; color: white; padding: 15px;">
            <div class="container">
                <div class="columns">
                    <div class="column is-10">
                        <h2>WE SHAPE. CONNECT. EDUCATE.</h2>
                        <p style="font-size: 14px;">
                            AESC and its members share a deep commitment to the highest quality standards in executive search and leadership consulting–for the benefit of clients and the profession.
                        </p>
                    </div>
                    <div class="column">
                        <div class="columns" style="border-left: solid 1px #fff;">
                            <div class="column">
                                <img src="@/assets/logos/handbookIcon.png">
                            </div>
                            <div class="column">
                                <a class="is-white-link" style="font-size: 14px; color: white!important" href="/member/membership/aesc-member-handbook">
                                    My AESC Member Handbook
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <div class="section">
            <div id="search" class="container">
                <div class="column">
                    <div class="columns" id="headerLevel">
                        <div class="column">
                            <div v-show="!query.Account">
                                <h1>
                                    AESC Member Directory
                                </h1>
                            </div>
                            <div v-show="query.Account">
                                <h1>
                                    Executive Search Firm Directory
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div v-show="!query.Account">
                                <b-button type="is-white" class="is-pulled-right" id="changeLink"
                                          @click="swapSearch">
                                    Find an AESC Member Office
                                </b-button>
                            </div>
                            <div v-show="query.Account">
                                <b-button type="is-white" class="is-pulled-right" id="changeLink"
                                          @click="swapSearch">
                                    Find an AESC Vetted Consultant
                                </b-button>
                            </div>
                        </div>
                    </div>

                    <b-message title=" " :closable="false" id="directorySearchArea">
                        <div class="columns">
                            <div class="column is-6">
                                <b-field horizontal label="Industry">
                                    <b-select placeholder="Select an Industry"
                                              v-model="query.Industry"
                                              expanded>
                                        <option value="">Select an Industry</option>
                                        <option v-for="option in industries"
                                                :value="option"
                                                :key="option">
                                            {{ option }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <b-field horizontal label="Function">
                                    <b-select placeholder="Select a Function"
                                              v-model="query.Function"
                                              expanded>
                                        <option value="">Select a Function</option>
                                        <option v-for="option in functions"
                                                :value="option"
                                                :key="option">
                                            {{ option }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <b-field horizontal label="Service">
                                    <b-select placeholder="Select a Service"
                                              v-model="query.Service"
                                              expanded>
                                        <option value="">Select a Service</option>
                                        <option v-for="option in services"
                                                :value="option"
                                                :key="option">
                                            {{ option }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <b-field horizontal label="Firm">
                                    <b-select placeholder="Select a Firm"
                                              v-model="query.AccountObj"
                                              expanded>
                                        <option value="">Select a Firm</option>
                                        <option v-for="option in accounts"
                                                :value="option"
                                                :key="option.Id">
                                            {{ option.Name }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <div v-show="!query.Account">
                                    <b-field horizontal label="Keywords">
                                        <b-input id="keywords"
                                                 v-model="query.Keywords"
                                                 @keyup.enter.native="search"
                                                 placeholder="">
                                        </b-input>
                                    </b-field>
                                </div>
                                <br />
                                <div v-show="!query.Account">
                                    <b-field horizontal label="Consultant Name">
                                        <b-input id="consultantName"
                                                 v-model="query.ConsultantName"
                                                 @keyup.enter.native="search"
                                                 placeholder="">
                                        </b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="column is-6">
                                <b-field horizontal label="Region">
                                    <b-select placeholder="Select a Region"
                                              v-model="query.Region"
                                              expanded>
                                        <option value="">Select a Region</option>
                                        <option v-for="option in regions"
                                                :value="option"
                                                :key="option">
                                            {{ option }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <b-field horizontal label="Country">
                                    <b-select placeholder="Select a Country"
                                              v-model="query.Country"
                                              expanded>
                                        <option value="">Select a Country</option>
                                        <option v-for="option in countries"
                                                :value="option"
                                                :key="option">
                                            {{ option }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <!--<b-field horizontal label="State">
        <b-select placeholder="Select a State"
                  v-model="query.State"
                  expanded>
            <option value="">Select a State</option>
            <option v-for="option in states"
                    :value="option"
                    :key="option">
                {{ option }}
            </option>
        </b-select>
    </b-field>-->
                                <b-field horizontal label="City">
                                    <b-input id="city"
                                             v-model="query.City"
                                             @keyup.enter.native="search"
                                             placeholder="">
                                    </b-input>
                                </b-field>
                                <b-field horizontal>
                                    <b-button class="blue-button"
                                              :disabled="searching"
                                              @click="search">
                                        Search
                                    </b-button>
                                </b-field>
                                <a @click="clearSearch" class="is-pulled-right" id="awardBlue">
                                    Clear Filters
                                </a>

                            </div>
                        </div>
                    </b-message>
                    <div v-show="searching">
                        <span>Loading ... </span><i class="fas fa-sync fa-spin"></i>
                    </div>
                    <div v-if="query.hasBeenSearched && !searching">
                        <div class="level">
                            <p class="level-right" id="contactsFound">{{ searchResp.length + (query.Account == true? ' offices' : ' contacts') + ' found.'}}</p>
                        </div>
                    </div>
                    <div id="responseResults" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="5">
                        <div v-if="!query.Account"
                             class="columns is-multiline"
                             style="cursor: pointer">
                            <div v-for="c in respDisplay"
                                 :key="c.Id"
                                 @click="goToContactProfile(c.Id)"
                                 class="column is-half">
                                <a class="card-text" :href="'/directory/profile/' + c.Id">
                                    <contactResult :contact="c" />
                                </a>
                            </div>
                        </div>
                        <div v-if="query.Account"
                             class="columns is-multiline"
                             style="cursor: pointer">
                            <div v-for="a in respDisplay"
                                 :key="a.Id"
                                 class="column is-half"
                                 @click="goToAccountProfile(a.Id)">
                                <a class="card-text" :href="'/directory/firm/' + a.Id">
                                    <accountResult :account="a" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import contactResult from "@/components/directory/search/contactResult.vue";
    import accountResult from "@/components/directory/search/accountResult.vue";
    import { Store } from "vuex";
    export default {
        name: "Search",
        components: {
            contactResult,
            accountResult
        },
        data() {
            return {
                error: String,
                query: {},
                accounts: [],
                functions: [],
                industries: [],
                states: [],
                countries: [],
                services: [],
                regions: [],
                searchResp: [],
                respDisplay: [],
                searching: false,
                busy: false
            }
        },
        async created() {
            var storeQuery = this.$store.getters["directory/postQuery"];
            console.log("storequery", storeQuery);
            if (storeQuery != null && storeQuery.Account != null) {
                this.query = storeQuery;
                this.search();
            } else {
                this.query.Account = false;
                this.query.AccountObj = null;
            }
            this.error = null;

            await axios
                .get("api/functions/tags")
                .then(resp => {
                    this.functions = resp.data;
                })
                .catch(err => {
                    this.error = err;
                });

            await axios
                .get("api/industries/tags")
                .then(resp => {
                    this.industries = resp.data;
                })
                .catch(err => {
                    this.error = err;
                });

            await axios
                .get("api/services/tags")
                .then(resp => {
                    this.services = resp.data;
                })
                .catch(err => {
                    this.error = err;
                });

            await axios
                .get("api/directory/countries")
                .then(resp => {
                    this.countries = resp.data;
                })
                .catch(err => {
                    this.error = err;
                });

            await axios
                .get("api/directory/regions")
                .then(resp => {
                    this.regions = resp.data;
                })
                .catch(err => {
                    this.error = err;
                });

            await axios
                .get("api/directory/states")
                .then(resp => {
                    this.states = resp.data;
                })
                .catch(err => {
                    this.error = err;
                });

            await axios
                .get("api/accounts/filter")
                .then(resp => {
                    this.accounts = resp.data;
                })
                .catch(err => {
                    this.error = err;
                });
        },
        methods: {
            loadMore() {
                if (this.searchResp.length - this.respDisplay.length < 20)
                    for (var i = this.respDisplay.length; i < this.searchResp.length; i++) {
                        if (this.searchResp[i])
                            this.respDisplay.push(this.searchResp[i]); // Load the rest of the data
                    }
                else {
                    for (var i = 0; i < 20; i++) {
                        if (this.searchResp[this.respDisplay.length])
                            this.respDisplay.push(this.searchResp[this.respDisplay.length]);
                    }
                }
            },
            clearSearch: function () {
                var account = this.query.Account;
                this.query = {};
                this.query.Account = account;
                this.searchResp = [];
                this.respDisplay = [];
                this.$store.dispatch("directory/updatePostQuery", {});
            },
            swapSearch: function () {
                this.query.Account = !this.query.Account;
                this.searchResp = [];
                this.respDisplay = [];
            },
            goToAccountProfile: function (accountId) {
                //this.$router.push({ name: 'accountProfile', params: { id: accountId } });
                this.$store.dispatch("directory/updatePostQuery", this.query);
            },
            goToContactProfile: function (contactId) {
                //this.$router.push({ name: 'contactProfile', params: { id: contactId } });
                this.$store.dispatch("directory/updatePostQuery", this.query);
            },
            async search() {
                if (this.query.State || this.query.City || this.query.Function || this.query.Industry || this.query.AccountObj || this.query.Keywords || this.query.ConsultantName || this.query.Region || this.query.Country || this.query.Service) {

                    this.searching = true;
                    this.searchResp = [];
                    this.respDisplay = [];
                    this.error = '';

                    if (this.query.AccountObj && this.query.AccountObj.Id) {
                        this.query.AccountId = this.query.AccountObj.Id;
                    } else {
                        this.query.AccountId = null;
                    }

                    this.query.hasBeenSearched = true;

                    await axios.post("api/directory/search",
                        this.query)
                        .then(resp => {
                            this.searchResp = resp.data;
                            if (this.searchResp.length < 20) {
                                for (var i = 0; i < this.searchResp.length; i++) {
                                    if (this.searchResp[i])
                                        this.respDisplay.push(this.searchResp[i]); // Load the rest of the data
                                }
                            } else {
                                for (var i = 0; i < 20; i++) {
                                    this.respDisplay.push(this.searchResp[i]);
                                }
                            }
                            this.searching = false;
                            //console.log("resp", resp.data);
                        }).catch(err => {
                            this.error = err;
                            console.log("error", err);
                            this.searching = false;
                        });
                } else {
                    this.respDisplay = [];
                    this.query.hasBeenSearched = false;
                    this.$buefy.toast.open({
                        message: "You Must Enter at Least One Filter to Search",
                        duration: 8000
                    });
                }

            }
        },


    }
</script>

<style>
    #directorySearchArea {
        background-color: white;
        border-color: #dbdbdb;
        border-radius: 4px;
        border-style: solid;
        border-width: 0 1px 1px 1px;
    }

        #directorySearchArea .message-header {
            background-color: whitesmoke;
            border-color: #dbdbdb !important;
            border-top: solid;
            border-bottom: solid;
            border-width: 1px 1px 1px 1px;
        }

    #headerLevel.level {
        padding-top: 50px;
    }

    #responseResults .message {
        border: none;
    }

    #responseResults .message-body {
        border: none;
    }

    .card-text {
        color: black;
    }

    .card-text:hover {
        color:black;
    }
</style>