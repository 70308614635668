import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store/store.js";

import Axios from "axios"
Vue.prototype.$http = Axios;

import Buefy from "buefy";
import 'buefy/dist/buefy.css';
Vue.use(Buefy, {
	defaultIconPack: "fas",
});

import VueHead from 'vue-head';
Vue.use(VueHead);

import moment from "vue-moment";
Vue.use(moment);

import '@/assets/css/global.scss';

import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {
	maxHeight: "100px",
	hideModules: {
		"link": true,
		"code": true,
		"image": true,
		"table": true,
		"separator": true,
		"headings": true,
		"unorderedList": true,
	}
});

Vue.config.productionTip = false;

import infiniteScroll from 'vue-infinite-scroll';
Vue.use(infiniteScroll);

Axios.defaults.baseURL = process.env.VUE_APP_AESC_API;
Vue.prototype.$APIURL = process.env.VUE_APP_AESC_API;
Vue.prototype.$HOMEURL = process.env.VUE_APP_AESC_HOME;

import "@/services/raygun.js";

export default new Vue({
	router,
	store,
	created() {
		Axios.interceptors.request.use(
			config => {
				const token = localStorage.getItem("AESC_AUTH_TOKEN");
				if (token) {
					config.headers["Authorization"] = "Bearer " + token;
				}
				return config;
			},
			err => {
				Promise.reject(err);
			});


		var refreshing = false;

		Axios.interceptors.response.use(resp => {
			return resp;
		}, async (err) => {
			if (err.response.status === 401) {
				if (err.response.headers["token-expired"] == "true" && !refreshing) {
					refreshing = true;
					return new Promise((resolve, reject) => {
						Axios.post("api/security/refresh", { refreshToken: localStorage.getItem("AESC_REFRESH_TOKEN"), authToken: localStorage.getItem("AESC_AUTH_TOKEN") }).then(async (resp) => {
							store.dispatch("security/refresh", resp.data);
							refreshing = false;

							resolve(Axios.request(err.config));

						}).catch(e => {
							store.dispatch("security/logout");
							router.push({ name: "login" });
							reject(e);
						});

					})
				} else {
					store.dispatch('security/logout');
					router.push({ name: "login" });
				}
				return err.response;
			}
			else if (err.response.status === 404) {
				window.location.href = "/404";
			}
			return Promise.reject(err);
		});
	},
	render: h => h(App)
}).$mount("#app");
