<template>
	<div class="container">
		<br />
		<p class="title is-2 aesc-title">AESC MEMBER REGISTRATION</p>
		<br />
		<div v-show="loading">
			<span>Loading ... </span><i class="fas fa-sync fa-spin"></i>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	export default {
		name: "confirmRegister",
		props: {
			confirmationId: String
		},
		data() {
			return {
				loading: false
			}
		},
		async created() {
			this.loading = true;
			await axios.post("api/security/completeRegister/" + this.confirmationId)
				.then(resp => {
					this.loading = false;
					if (resp.data) {
						this.$buefy.modal.open('<div class="modal-card-body"><p>Thank you for confirming your account.Your account has been created.Please check your email for access instructions.<br /><br />To access your new AESC account visit <a href="https://www.aesc.org/login">www.aesc.org/login</a></p></div>');
						this.$router.push({ path: "/login" });
					}
				})
				.catch(err => {
					this.loading = false;
					if (err.toString().includes("record already exists")) {
						this.$buefy.modal.open('<div class="modal-card-body"><p>The confirmation id was invalid or has already been used. Please check your email.</p></div>');
					} else {
						this.$buefy.modal.open('<div class="modal-card-body"><p>An uncaught issue occurred. Please try again later.</p></div>');
					}
				});
		}
	}
</script>

<style>
</style>
