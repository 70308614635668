<template>
	<div class="section">
		<div class="container">
			<h1>Forgot your password?</h1>
			<b-message>
				<b-field label="E-mail Address *">
					<b-input id="userEmail"
							 icon="envelope-square"
							 type="email"
							 v-model="email">
					</b-input>
				</b-field>
				<b-button class="red-button"
						  :class="{'is-loading': loading}"
						  @click="forgotPassword">
					Email my password reset link
				</b-button>
			</b-message>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	export default {
		name: "ForgotPassword",
		data() {
			return {
				email: '',
				loading: false
			}
		},
		methods: {
			async forgotPassword() {
				this.loading = true;
				await axios
					.post("api/security/forgotPassword/" + this.email)
					.then(resp => {
						this.loading = false;
						this.$buefy.toast.open({
							message: resp.data,
							duration: 8000
						})
					})
					.catch(err => {
						this.loading = false;
						this.$buefy.toast.open({
							message: err,
							type: 'is-danger',
							duration: 8000
						})
					});
			}
		}
	}
</script>
