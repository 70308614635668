<template>
	<div id="app">
		<div id="aescHeader">
			<navigationHeader></navigationHeader>
		</div>
		<router-view />
		<br />
		<div id="aescFooter">
			<navigationFooter></navigationFooter>
		</div>
	</div>
</template>

<script>
	import navigationHeader from "@/components/navigation/navigationHeader.vue";
	import navigationFooter from "@/components/navigation/navigationFooter.vue";

	export default {
		name: 'App',
		components: {
			navigationHeader,
			navigationFooter
		},
		created() {

		}
	};
</script>

<style>
</style>
