<template>
	<div class="modal-card">
		<div class="modal-card-body">
			<div class="columns">
				<div class="column">
					<div class="title is-4 is-uppercase">CONTACT {{ contact.FirstName }} {{ contact.LastName }}</div>
					<b-input type="text" placeholder="Subject (required)" v-model="title"></b-input>
					<br />
					<wysiwyg id="wysiwig"
							 v-model="message" />
				</div>
			</div>
		</div>
		<div class="modal-card-foot">
			<b-button @click="$parent.close()">Cancel</b-button>
			<b-button class="is-primary" @click="requestChange">Send Request</b-button>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			contact: {}
		},
		data() {
			return {
				title: "",
				message: ""
			}
		},
		methods: {
			async requestChange() {
				if (this.title && this.message) {
					await this.$http.post("api/directory/message", { To: this.contact.Id, Title: this.title, Message: this.message })
						.then(async resp => {
							this.$buefy.toast.open({
								message: resp.data,
								duration: 8000
							});
							this.$parent.close();
						}).catch(err => {
							console.log(err);
						})
				} else {
					this.$buefy.toast.open({
						message: "Please fill out both fields.",
						duration: 8000
					});
				}
			}
		}
	}
</script>
