const state = {
	postQuery: JSON.parse(localStorage.getItem("POST_LOGIN_QUERY")),
	preQuery: JSON.parse(localStorage.getItem("PRE_LOGIN_QUERY")),
};

const mutations = {
	SET_POST_QUERY(state, query) {
		state.postQuery = query;
		localStorage.setItem("POST_LOGIN_QUERY", JSON.stringify(query));
	},
	SET_PRE_QUERY(state, query) {
		state.preQuery = query;
		localStorage.setItem("PRE_LOGIN_QUERY", JSON.stringify(query));
	}
};

const actions = {
	updatePostQuery: ({ commit, state }, query) => {
		commit('SET_POST_QUERY', query);
	},
	updatePreQuery: ({ commit, state }, query) => {
		commit('SET_PRE_QUERY', query);
	},
}

const getters = {
	postQuery: state => {
		return state.postQuery;
	},
	preQuery: state => {
		return state.preQuery;
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
