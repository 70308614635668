import Vue from "vue";
import Router from "vue-router";
import store from "@/store/store.js";
import profile from "@/components/profile/profile.vue";
import login from "@/components/security/login.vue";
import register from "@/components/security/register.vue";
import search from "@/components/directory/search/search.vue";
import contactProfile from "@/components/directory/profile/contactProfile.vue";
import accountProfile from "@/components/directory/profile/accountProfile.vue";
import searchFirms from "@/components/publicSearch/searchFirms.vue";
import searchFirm from "@/components/publicSearch/searchFirm.vue";
import memberSearch from "@/components/publicSearch/memberSearch";
import clientRegistration from "@/components/forms/clientRegistration.vue";
import unsubscribe from "@/components/subscriptions/Unsubscribe.vue";
import forgotPassword from "@/components/security/forgotPassword.vue";
import resetPassword from "@/components/security/resetPassword.vue";
import messaging from "@/components/messaging/messaging.vue";
import confirmRegister from "@/components/security/confirmRegister.vue";
import dashboardVue from "./components/navigation/dashboard.vue";
import userAdmin from "@/components/profile/userAdmin.vue";

import { raygun } from "@/services/raygun.js";

Vue.use(Router);

//var roleName = {
//    Network: "Network",
//    Firm: "Firm",
//    Office: "Office",
//    SearchConsultant = "SearchConsultant",
//    Client = "Client",
//    General = "General",
//    Advisor = "Advisor",
//    Master = "Master",
//    Partner = "Partner"
//}

//var matchType = {
//    AND: 'and',
//    OR: 'or'
//}

let router = new Router({
	mode: "history",
	routes: [
		{
			path: "/dashboard",
			name: "dashboard",
			component: dashboardVue,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: "/profile",
			//alias: "/profile/",
			name: "profile",
			component: profile,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: "/temp/login",
			name: "login",
			component: login
		},
		{
			path: "/register",
			name: "register",
			component: register
		},
		{
			path: "/directory",
			name: "search",
			component: search,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: "/directory/profile/:id",
			name: 'contactProfile',
			component: contactProfile,
			props: true,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: "/directory/firm/:id",
			name: 'accountProfile',
			component: accountProfile,
			props: true,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: "/search-firms",
			name: "searchFirms",
			component: searchFirms
		},
		{
			path: "/search-firm/:name",
			name: "searchFirm",
			component: searchFirm,
			props: true
		},
		{
			path: "/members",
			name: "memberSearch",
			component: memberSearch
		},
		{
			path: "/clientregistration/register",
			name: "clientRegistration",
			component: clientRegistration
		},
		{
			path: "/unsubscribe/:unsubId",
			name: "unsubscribe",
			component: unsubscribe,
			props: true
		},
		{
			path: "/forgotPassword",
			name: "forgotPassword",
			component: forgotPassword
		},
		{
			path: "/resetPassword/:code",
			name: "resetPassword",
			component: resetPassword,
			props: true
		},
		{
			path: "/inbox",
			name: "messaging",
			component: messaging,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: "/register/confirm/:confirmationId",
			name: "confirmRegister",
			component: confirmRegister,
			props: true
		},
		{
			path: "/manage/user",
			name: "userAdmin",
			component: userAdmin,
			meta: {
				requiresAuth: true
			}
		}
	]
});

router.beforeEach(async (to, from, next) => {
	console.log(to.path);
	if (!store.getters["security/context"]) {
		store.dispatch('security/init', {
			context: router.app
		});
	}

	if (to.path.startsWith("/resetPassword")) {
		var separations = to.path.split("/");
		separations = separations.filter(s => {
			if (s != null || s != "") {
				return s;
			}
		});
		if (separations.length >= 3) {
			var path = to.path;
			var last = 0;
			for (var i = 0; i < 1; i++) {
				last = path.indexOf('/', last + 1);
			}
			var token = path.substring(last + 1);
			var newPath = path.substring(0, last);
			token = token.replace(/\//g, "%2F");
			window.location.href = newPath + '/' + token;
		}
	}

	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (to.path.startsWith("/admin")) {
			if (store.getters["security/isAdmin"]) {
				next();
			}
			else {
				router.app.$buefy.toast.open("You do not have permission to see this page.");
				window.location.href = '/login';
			}
		}

		if (!store.getters["security/isLoggedIn"]) {
			window.location.href = '/login?ReturnURL=' + process.env.VUE_APP_AESC_URL + to.path;
			return;
		} else if (store.getters["security/isLoggedIn"]) {
			if (localStorage.getItem("AESC_LOGIN") == undefined || localStorage.getItem("AESC_LOGIN") == null) {
				await store.dispatch("security/login", localStorage.getItem("AESC_REFRESH_TOKEN"))
					.then(async resp => {
						next();
					}).catch(err => {
						window.location.href = '/login';
						return;
					})

			} else {
				next();
			}
		} else {
			next();
		}
	} else {
		next();
	}
});

router.afterEach((to, from) => {
	raygun('trackEvent', {
		type: 'pageView',
		path: to.path
	});
	window.scrollTo(0, 0);
});

export default router;
