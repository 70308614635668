import rg4js from "raygun4js";

rg4js('apiKey', process.env.VUE_APP_AESC_RAYGUN);
rg4js('enableCrashReporting', true);
rg4js('enablePulse', true);
rg4js('options', {
	allowInsecureSubmissions: false,
	ignoreAjaxAbort: true,
	ignoreAjaxError: true,
	debugMode: false,
	ignore3rdPartyErrors: false,
	wrapAsynchronousCallbacks: true,
	excludedHostnames: ['\.local'],
	excludedUserAgents: ['Mosaic'],
	disableErrorTracking: false,
	disablePulse: false,
	pulseMaxVirtualPageDuration: 1800000,
	pulseIgnoreUrlCasing: false,
	captureUnhandledRejections: true,
	setCookieAsSecure: false,
	captureMissingRequests: false
});

export function raygun(action, options) {
	rg4js(action, options);
}

