<template>
	<div id="displProfile" class="container">
		<h1>
			AESC Profile
		</h1>
		<div class="columns">
			<div class="column">
				<b-collapse aria-id="contentPi"
							class="message"
							:open.sync="piOpen">
					<div slot="trigger"
						 class="message-header"
						 role="button"
						 aria-controls="contentPi">
						<p class="title is-4">Personal Information</p>
						<b-icon :icon="piOpen ? 'angle-up' : 'angle-down'"></b-icon>
					</div>
					<div class="message-body">
						<b-button id="editButton"
								  class="blue-button"
								  @click="editProfile">
							Edit
						</b-button>
						<br />
						<br />
						<img id="personalPhoto" :src="$APIURL + contact.PhotoSrc" style="max-height: 150px;">
						<p v-if="contact.prefix">
							<strong>Prefix</strong><br>{{ contact.Prefix }}
						</p>
						<p><strong>First Name</strong><br>{{ contact.FirstName }}</p>
						<p><strong>Last Name</strong><br>{{ contact.LastName }}</p>
						<p><strong>Title</strong><br>{{ contact.Title }}</p>
						<div v-show="contact.Services"
							 style="padding-bottom: 5px">
							<strong>Services</strong>
							<ul>
								<li v-for="s in contact.Services"
									:key="s.id">
									{{ s }}
								</li>
							</ul>
						</div>
						<div v-if="contact.Industries"
							 style="padding-bottom: 5px">
							<strong>Industry Specialties</strong>
							<ul>
								<li v-for="industry in contact.Industries"
									:key="industry.id">
									{{ industry }}
								</li>
							</ul>
						</div>
						<div v-if="contact.Functions"
							 style="padding-bottom: 5px">
							<strong>Functional Specialties</strong>
							<ul>
								<li v-for="f in contact.Functions"
									:key="f.id">
									{{ f }}
								</li>
							</ul>
						</div>
						<strong>Bio</strong><br>
						<p class="biotext"
						   v-html="contact.Bio__c" />
					</div>
				</b-collapse>
				<b-collapse aria-id="contentOi"
							class="message"
							:open.sync="oiOpen">
					<div slot="trigger"
						 class="message-header"
						 role="button"
						 aria-controls="contentOi">
						<p class="title is-4">Office Information</p>
						<b-icon :icon="oiOpen ? 'angle-up' : 'angle-down'"></b-icon>
					</div>
					<div class="message-body">
						<div class="columns">
							<div class="column">
								<b-button role="button"
										  icon-left="envelope-square"
										  type="blue-button"
										  @click="requestChange('Office Information')"
										  style="margin:10px">Request Change</b-button>
							</div>
						</div>
						<p>
							<strong>Office:</strong>
							{{ office.Name }}
						</p>
						<p v-if="office.BillingStreet">
							<strong>Mailing Address:</strong>
							{{ office.BillingStreet }}
						</p>
						<p v-if="office.BillingCity">
							<strong>City:</strong>
							{{ office.BillingCity }}
						</p>
						<p v-if="office.BillingState">
							<strong>State/Territory:</strong>
							{{ office.BillingState }}
						</p>
						<p v-if="office.BillingPostalCode">
							<strong>Postal Code:</strong>
							{{ office.BillingPostalCode }}
						</p>
						<p v-if="office.BillingCountry">
							<strong>Country:</strong>
							{{ office.BillingCountry }}
						</p>
						<br />
						<p v-if="office.Bio_RT__c">
							<strong>Bio</strong><br>
							{{ office.Bio_RT__c }}
						</p>
						<br />
						<p>
							<strong>Compensation Visibility:</strong>
							<span v-show="office.ShowCompensation__c">Compensation visibility is unrestricted</span><br>
							<span v-show="!office.ShowCompensation__c">This firm is not allowing compensation visibility</span>
						</p>
					</div>
				</b-collapse>
				<b-collapse aria-id="contentCi"
							class="message"
							:open.sync="ciOpen">
					<div slot="trigger"
						 class="message-header"
						 role="button"
						 aria-controls="contentCi">
						<p class="title is-4">Contact Information</p>
						<b-icon :icon="ciOpen ? 'angle-up' : 'angle-down'"></b-icon>
					</div>
					<div class="message-body">
						<div class="columns">
							<div class="column">
								<b-button role="button"
										  icon-left="envelope-square"
										  type="blue-button"
										  @click="requestChange('Contact & Subscription Information')"
										  style="margin:10px">Request Change</b-button>
							</div>
						</div>
						<div class="columns">
							<div class="column"
								 style="border-right:solid 1px gray">
								<div v-if="contact.Phone"><strong>Phone:</strong> {{ contact.Phone }}</div>
								<div v-if="contact.Fax"><strong>Fax:</strong> {{ contact.Fax }}</div>
								<div v-if="contact.Email"><strong>E-mail:</strong> {{ contact.Email }}</div>
							</div>
							<div class="column">
								<div>
									<span><strong>Email Subscriptions</strong></span>
								</div>
								<div>
									<a :href="unsubUrl"
									   target="_blank">My Subscription Preferences</a>
								</div>
							</div>
						</div>
					</div>
				</b-collapse>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	import requestChange from "@/components/profile/requestChange.vue";
	export default {
		name: "displayProfile",
		props: {
			contact: {},
			unsubUrl: String,
			office: {}
		},
		data() {
			return {
				saving: false,
				piOpen: true,
				ciOpen: false,
				oiOpen: false,
			}
		},
		methods: {
			editProfile() {
				this.$parent.edit = true;
			},
			requestChange(requestString) {
				this.$buefy.modal.open({
					parent: this,
					component: requestChange,
					hasModalCard: true,
					props: {
						request: requestString,
					},
				})
			},
		}
	}
</script>

<style>
	#displProfile .title {
		padding-top: 20px;
	}

	collapse:not(.show) {
		display: normal !important;
	}

	#displProfile .message-header {
		height: 65px;
		background-color: rgb(57, 96, 154) !important;
	}

		#displProfile .message-header .title {
			color: white;
		}
</style>
