<template>
    <div class="section">
        <div id="accountProfile" class="container">
            <div v-show="isLoading">
                <span>Loading ... </span><i class="fas fa-sync fa-spin"></i>
            </div>
            <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
            <br />
            <b-button class="is-light" @click="backToDirectory">
                Back To Search
            </b-button>
            <br /><br />
            <div v-if="account">
                <h1>{{ account.Name }}</h1>
                <br />
                <div class="level is-mobile" style="padding-bottom: 20px;">
                    <div class="level-left">
                        <div class="level-item" v-if="account.LinkedIn_Firm__c">
                            <b-button class="blue-button" @click="goToLinkedIn(account.LinkedIn_Firm__c)"><img src="@/assets/logos/white-linkedin-logo.png" style="max-height: 20px;" />&nbsp;&nbsp;Follow on LinkedIn</b-button>
                        </div>
                        <div class="level-item" v-if="account.Award_Firm__c" id="awardBlue">
                            <i class="fas fa-award" style="max-height: 50px;"></i>
                        </div>
                        <div class="level-item" v-if="account.Award_Firm__c" id="awardBlue">
                            <a href="https://www.aesc.org/archive/aesc-award-recipients" target="_blank" class="is-vcentered">AESC Award Recipient</a>
                        </div>
                    </div>
                </div>
                <div v-if="account.Website">
                    <p class="has-text-weight-bold">Website</p>
                    <a :href="account.Website">{{ account.Website }}</a>
                    <br />
                </div>
                <br />
                <div v-if="account.Phone">
                    <p class="has-text-weight-bold">Phone</p>
                    <p>{{ account.Phone }}</p>
                    <br />
                </div>
                <div v-if="account.Billing">
                    <p class="has-text-weight-bold">Address</p>
                    <p>{{ account.Billing.City }}, {{ account.Billing.State }}</p>
                    <p>{{ account.Billing.Street }}</p>
                    <p>{{ account.Billing.Country }}</p>
                    <br />
                </div>
                <br />
                <p v-if="account && account.NumberOfConsultants > 0" class="has-text-weight-bold">Number of Consultants at this  {{ account.Parent == null ? 'Firm:' : 'Office:'}} {{account.NumberOfConsultants}}</p>
                <br />
                <div v-if="account.Bio || (account.Parent && account.Parent.Bio)">
                    <p v-html=" account.Bio || account.Parent.Bio"></p>
                </div>
                <br />
                <div v-if="account.IndustrySpecialties && account.IndustrySpecialties.length > 0">
                    <p class="has-text-weight-bold">Industry Specialties</p>
                    <p v-for="i in account.IndustrySpecialties">{{ i }}</p><br />
                </div>
                <div v-if="account.FunctionalSpecialties && account.FunctionalSpecialties.length > 0">
                    <p class="has-text-weight-bold">Functional Specialties</p>
                    <p v-for="f in account.FunctionalSpecialties">{{ f }}</p> <br />
                </div>
                <div v-if="account.Services && account.Services.length > 0">
                    <p class="has-text-weight-bold">Services</p>
                    <p v-for="s in account.Services">{{ s }}</p>
                </div>
                <br />
                <div v-if="account.IsParent">
                    <h4 class="has-text-weight-bold is-uppercase">{{ account.Name }} LOCATIONS</h4><br />
                    <div class="columns is-multiline">
                        <div class="column is-half" v-for="o in account.Offices" :key="o.Id" @click="goToAccountProfile(o.Id)">
                            <accountResult style="cursor: pointer;" :account="o"></accountResult>
                        </div>
                    </div>
                    <br />
                </div>
                <div v-if="!account.IsParent && consultants.length > 0" id="consultants">
                    <p class="has-text-weight-bold text-uppercase">CONSULTANTS</p>
                    <div class="columns is-multiline">
                        <div class="column is-half" v-for="c in consultants" :key="c.Id" @click="goToContactProfile(c.Id)">
                            <contactResult style="cursor: pointer;" :contact="c"></contactResult>
                        </div>
                    </div>
                    <br />
                </div>
                <div v-if="parent && parent.Id" id="parent">
                    <h4 class="has-text-weight-bold text-uppercase">PARENT</h4><br />
                    <div class="column" @click="goToAccountProfile(parent.Id)">
                        <accountResult style="cursor: pointer;" :account="account.Parent"></accountResult>
                    </div>
                    <br />
                </div>
                <div v-if="!account.IsParent && account.Parent" id="parentOffices">
                    <h4 class="has-text-weight-bold is-uppercase">{{ parent.Name }} OFFICES</h4><br />
                    <div class="columns is-multiline">
                        <div class="column is-half" v-for="o in account.Parent.Offices" :key="o.Id" @click="goToAccountProfile(o.Id)">
                            <accountResult style="cursor: pointer;" :account="o"></accountResult>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import contactResult from "@/components/directory/search/contactResult.vue";
    import accountResult from "@/components/directory/search/accountResult.vue";
    export default {
        name: "accountProfile",
        components: {
            contactResult,
            accountResult
        },
        props: {
            id: String,
        },
        data() {
            return {
                account: {},
                consultants: [],
                parent: {},
                err: '',
                isLoading: false
            }
        },
        watch: {
            '$route': 'getNewAccount'
        },
        mounted() {

        },
        created: async function () {
            this.isLoading = true;

            await axios.post("api/accounts/directory/" + this.id)
                .then(resp => {
                    this.account = resp.data;
                    this.consultants = this.account.Consultants != null ? this.account.Consultants : [];
                    this.parent = this.account.Parent;
                    this.isLoading = false;
                }).catch(err => {
                    this.err = err;
                    this.isLoading = false;
                });
            /*
                    await axios.post("api/accounts/directory/" + this.account.ParentId)
                        .then(resp => {
                            this.parent = resp.data;
                            this.isLoading = false;
                        }).catch(err => {
                            this.err = err;
                        });
                        */
        },
        methods: {
            async getNewAccount() {
                this.isLoading = true;
                window.scrollTo(0, 0);

                await axios.post("api/accounts/directory/" + this.$route.params.id)
                    .then(resp => {
                        this.account = resp.data;
                        this.parent = resp.data.Parent;
                        this.consultants = this.account.Consultants != null ? this.account.Consultants : [];
                        this.isLoading = false;
                    }).catch(err => {
                        this.err = err;
                        this.isLoading = false;
                    });

                //await axios.post("api/accounts/directory/" + this.account.ParentId)
                //    .then(resp => {
                //        this.parent = resp.data;
                //    }).catch(err => {
                //        this.err = err;
                //    });

                this.isLoading = false;
            },
            goToAccountProfile: function (accountId) {
                this.$router.push({ name: 'accountProfile', params: { id: accountId } });
            },
            goToContactProfile: function (contactId) {
                this.$router.push({ name: 'contactProfile', params: { id: contactId } });
            },
            backToDirectory() {
                window.location.href = "/directory";
            },
            goToLinkedIn(link) {
                window.open(link, '_blank');
            }
        }
    }
</script>

<style>
    #accountProfile {
        text-align: left;
    }

    #consultants .message {
        height: 130px;
        border-left: solid;
        border-color: #dbdbdb;
    }

    #consultants .message-body {
        border-style: none;
    }
</style>