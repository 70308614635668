import { raygun } from "@/services/raygun.js";

const state = {
	authorization: localStorage.getItem("AESC_AUTH_TOKEN"),
	refresh: localStorage.getItem("AESC_REFRESH_TOKEN"),
	login: JSON.parse(localStorage.getItem("AESC_LOGIN")),
	context: undefined,
	contact: JSON.parse(localStorage.getItem("AESC_CONTACT")),
	unread: undefined,
};

const mutations = {
	SET_AUTH(state, params) {
		if (!params) {
			localStorage.removeItem("AESC_AUTH_TOKEN");
			localStorage.removeItem("AESC_REFRESH_TOKEN");
			state.authorization = undefined;
			state.refresh = undefined;
			localStorage.removeItem("AESC_LOGIN");
			state.login = undefined;
			localStorage.removeItem("AESC_CONTACT");
			localStorage.removeItem("BSDB_AGREED");
		} else {
			if (params.tokens) {
				state.authorization = params.tokens.authorization;
				delete params.tokens;
			}
			state.login = params;
		}
	},
	SET_CONTEXT(state, context) {
		state.context = context;
	},
	SET_CONTACT(state, contact) {
		state.contact = contact;
	},
	SET_TOKENS_DEMO(state, tokens) {
		state.authorization = tokens.authorization;
		state.refresh = tokens.refresh;
		localStorage.setItem("AESC_AUTH_TOKEN", tokens.authorization);
		localStorage.setItem("AESC_REFRESH_TOKEN", tokens.refresh);
	},
};

const actions = {
	init: ({ commit, state }, packages) => {
		commit('SET_CONTEXT', packages.context);
	},
	refresh: ({ commit, state }, params) => {
		var login = params;
		localStorage.setItem("AESC_AUTH_TOKEN", login.tokens.authorization);
		localStorage.setItem("AESC_REFRESH_TOKEN", login.tokens.refresh);
		localStorage.setItem("AESC_LOGIN", JSON.stringify(login));

		try {
			if (login) {
				raygun('setUser', {
					identifier: login.Id,
					isAnonymous: false,
					email: login.Email,
					fullName: login.firstName + login.lastName
				});
			}

		} catch (err) {
			console.log("failed raygun refresh");
		}

		var baseDomain = '.aesc.org';

		var expireAfter = new Date();
		expireAfter.setDate(expireAfter.getDate() + 3);

		document.cookie = "tokens={'authorization':'" + login.tokens.authorization + "', 'refresh': '" + login.tokens.refresh + "' }; domain=" + baseDomain + "; expires=" + expireAfter + "; path=/";

		console.log("refresh")
		commit('SET_AUTH', login);
		return login;
	},
	login: async ({ commit, state }, params) => {
		await state.context.$http.post("api/security/getLoginInfo", { Refresh: params })
			.then(async response => {

				if (response.data == "Could not find user.") {
					Promise.reject(response.data);
				}

				try {
					raygun('setUser', {
						identifier: response.data.Id,
						isAnonymous: false,
						email: response.data.Email,
						fullName: response.data.firstName + response.data.lastName
					});
				} catch (err) {
					console.log("failed login", err);
				}

				setCookie("tokens", '{"authorization":"' + state.authorization + '", "refresh": "' + state.refresh + '" }', 365);
				//document.cookie = "tokens={'authorization':'" + state.authorization + "', 'refresh': '" + state.refresh + "' }; domain=" + baseDomain + "; expires=" + expireAfter + "; path=/";

				localStorage.setItem("AESC_LOGIN", JSON.stringify(response.data));
				commit('SET_AUTH', response.data);
			}).catch(error => {
				Promise.reject(error);
			});
	},
	logout: ({ commit, state }) => {

		var baseDomain = '.aesc.org';

		var expireAfter = new Date();
		expireAfter.setDate(expireAfter.getDate() - 7);

		state.context.$http.get("api/security/logout").then(resp => {
			document.cookie = "tokens=; domain=" + baseDomain + "; expires=" + expireAfter + "; path=/";
			commit('SET_AUTH', null);
			window.location.href = "/login";
		}).catch(err => {
			document.cookie = "tokens=; domain=" + baseDomain + "; expires=" + expireAfter + "; path=/";
			commit('SET_AUTH', null);
			Promise.reject(err);
			window.location.href = "/login";
		});

	},
	handTokensForDemo: ({ commit, state }, params) => {
		commit('SET_TOKENS_DEMO', params);
	},
};

const getters = {
	isLoggedIn: state => {
		return !!state.authorization;
	},
	login: state => {
		return state.login;
	},
	isAdmin: state => {
		if (state.login && state.login.user && state.login.user.Email) {
			if (state.login.user.Email.indexOf('@aesc.org') >= 0 || state.login && state.login.user.Email.indexOf('@13prime.com') >= 0) {
				return true;
			}
			else {
				return false;
			}
		}
		else {
			return false;
		}
	},
	contact: state => {
		return state.contact;
	},
	unreadMessages: state => {
		if (state.login) {
			return state.login.unreadMessages;
		} else {
			return null;
		}
	},
	context: state => {
		return state.context;
	},
	canSeeDropdown: state => {
		if (state.login && state.login.user && state.login.user.userType == "member" && state.login.user.Access_Level__c == "Full Access") {
			return true;
		}
		else {
			return false;
		}
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}

function setCookie(name, value, days) {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (encodeURIComponent(value) || "") + expires + "; path=/; domain=" + process.env.VUE_APP_COOKIE_DOMAIN;
}
