<template>
	<div class="section">
		<div class="container">
			<h1>Temporary Dashboard</h1>
			<div class="columns">
				<div class="column links">
					<router-link to="/login">Login</router-link>
					<router-link to="/forgotPassword">Forgot Password</router-link>
					<router-link to="/register">Member Registration</router-link>
					<router-link to="/clientregistration/register">Client Registion</router-link>
					<router-link to="/search-firms">Search firms</router-link>
					<router-link to="/members">Public directory</router-link>
				</div>
				<div class="column links">
					<h2>Login required</h2>
					<router-link to="/profile">Profile</router-link>
					<router-link to="/inbox">AESC Inbox</router-link>
					<router-link to="/directory">Member Directory</router-link>
					<a href="https://bluesteps.aesc.org/">Bluesteps Database</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "dashboard",
		data: function () {
			return {
				dev: true
			};
		}
	};
</script>

<style>
	.links a {
		display: block;
		margin: 8px 0;
	}
</style>
