<template>
	<div class="container" id="subs">
		<div class="columns">
			<div class="column is-10">
				<p class="title is-3 aesc-title" style="margin-top: 20px;">
					Subscriptions
				</p>
				<p>
					Update your e-mail preferences for AESC communications
				</p>

				<div class="breathe-xl">
					<b-button style="margin-right: 10px;" type="is-primary" @click="subToAll">Subscribe to all</b-button>
					<b-button type="is-danger" @click="unsubToAll">Unsubscribe to all</b-button>
				</div>
				<div v-for="s in subscriptions" class="breathe-xl" v-bind:key="s.Title">
					<div v-if="s.Title == 'AESC Inform'" style="margin: 20px 0;" class="title is-4">Newsletters</div>
					<b-message>
						<h3 class="has-text-weight-bold">
							{{s.Title}}
						</h3>
						<p>
							{{s.Description}}
						</p>
						<div class="breathe">
							<b-checkbox type="is-warning"
										v-model="s.Checked"
										@input="subscribe(s.Title)"
										class="breathe">
								{{s.Checked ? 'subscribed' : 'unsubscribed'}}
							</b-checkbox>
						</div>
					</b-message>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from "axios";
	export default {
		name: "unsubscribe",
		props: {
			unsubId: String
		},
		data() {
			return {
				subscriptions: [],
				err: '',
				goodMessage: ''
			}
		},
		async created() {
			await this.GetMemberSubscriptions();
		},
		methods: {
			async GetMemberSubscriptions() {
				await axios
					.get("api/subscription/memberSubscriptions/" + this.unsubId)
					.then(resp => {
						this.subscriptions = resp.data;
					})
					.catch(err => {
						this.err = err;
					});
			},
			async subToAll() {
				await axios
					.get("api/subscription/subscribeAll/" + this.unsubId)
					.then(resp => {
						this.$buefy.toast.open({
							message: resp.data,
							duration: 8000
						});
						this.GetMemberSubscriptions();
					})
					.catch(err => {
						this.err = err;
					});
			},
			async unsubToAll() {
				await axios
					.get("api/subscription/unsubscribeAll/" + this.unsubId)
					.then(resp => {
						this.$buefy.toast.open({
							message: resp.data,
							duration: 8000
						});
						this.GetMemberSubscriptions();
					})
					.catch(err => {
						this.err = err;
					});
			},
			async subscribe(title) {
				var correctTitle = '';
				switch (title) {
					case "Alerts":
						correctTitle = "alerts";
						break;
					case "Education":
						correctTitle = "education";
						break;
					case "Events":
						correctTitle = "events";
						break;
					case "Products":
						correctTitle = "products";
						break;
					case "Research":
						correctTitle = "research";
						break;
					case "AESC Inform":
						correctTitle = "aescInform";
						break;
					case "AESC Perform":
						correctTitle = "aescPerform";
						break;
					case "AESC Excel":
						correctTitle = "aescExcel";
						break;
					case "AESC Impact":
						correctTitle = "aescImpact";
						break;
				}
				await axios
					.get("api/subscription/" + correctTitle + "/" + this.unsubId)
					.then(resp => {
						this.$buefy.toast.open({
							message: resp.data,
							duration: 8000
						});
					})
					.catch(err => {
						this.err = err;
					});
			}
		}
	}
</script>
<style>
	#subs .message-body {
		padding-bottom: 0px !important;
	}
</style>
