<template>
	<div class="modal-card">
		<div class="modal-card-head">
			<h2>Edit {{ contact.FirstName + ' ' + contact.LastName }}</h2>
		</div>
		<div class="modal-card-body">
			<div class="columns">
				<div class="column">
					<img class="is-pulled-left" id="personalPhoto" :src="$APIURL + contact.PhotoSrc" style="margin: 0 auto; display: block; max-height: 150px;" />
					<br />
				</div>
			</div>
			<br />
			<b-field label="Photo" horizontal>
				<b-upload accept=".jpg,.jpeg,.png," v-model="Photo">
					<a class="button blue-button" icon="fa-upload">Upload Image</a>
					<span style="margin-left: 7px;margin-top:5px;" class="has-text-weight-bold" v-if="Photo">
						{{ Photo.name }}
					</span>
				</b-upload>
			</b-field>
			<br />
			<h3>Edit the user's bio here.</h3>
			<br />
			<wysiwyg id="wysiwig"
					 v-model="contact.Bio__c" />
			<br />

		</div>
		<div class="modal-card-foot">
			<b-button @click="$parent.close()">Cancel</b-button>
			<b-button class="is-primary" @click="editUser">Edit User</b-button>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			User: {}
		},
		data() {
			return {
				contact: {},
				Photo: null
			}
		},
		created() {
			this.contact = this.User;
		},
		methods: {
			editUser() {
				if (this.contact.Bio__c) {
					this.$http.post("api/contacts/updateBio", { Bio: this.contact.Bio__c, UserId: this.contact.Id })
						.then(resp => {
							if (resp.data.Id) {
								this.$buefy.toast.open({
									message: "User updated successfully.",
									type: "is-success",
									duration: 8000
								})
								if (this.Photo != undefined && this.Photo.name) {
									var formData = new FormData();
									formData.append('file', this.Photo);
									this.$http.post("api/image/upload/" + this.contact.Id, formData, { headers: { "Content-Type": 'multipart/form-data' } })
										.then(async resp => {
											this.loading = false;
											if (resp.data.Result == "Fail") {
												this.$buefy.toast.open({
													message: "An error occurred uploading the photo.",
													type: "is-danger",
													duration: 8000
												})
											} else if (resp.data.Result == "Success") {
												this.$buefy.toast.open({
													message: "Photo uploaded successfully.",
													type: "is-success",
													duration: 8000
												})
											}
											delete this.Photo;
											this.Photo = undefined;
											this.$parent.close();
										}).catch(async err => {
											delete this.Photo;
											this.Photo = undefined;
											this.$parent.close();
										});
								}
								this.$parent.close();
							}
						})
				}
			},
		}
	}
</script>
