<template>
	<div id="messageContainer">
		<b-message>
			<div class="level">
				<div class="level-left">
					<b-button class="red-button" @click="showInbox"><b-icon class="fas fa-arrow-left"></b-icon> Back to Inbox</b-button>
				</div>
				<div class="level-right">
					<b-button type="is-danger" @click="deleteMessage" v-bind:disabled="message.deleted" v-show="message">Delete</b-button>
				</div>
			</div>
			<div class="columns">
				<div class="column">
					<p class="title is-4">{{message.BSMember.FirstName}} {{message.BSMember.LastName}}</p>
					<a :href="'https://bluesteps.aesc.org/profile/' + message.BSMember.UserId" v-if="message.Type != 'Client'"><strong>View Full BlueSteps Profile, Including Resume</strong></a>
				</div>
				<div class="column">
					<span v-if="message.Type == 'Client'">Prospective Client</span>
				</div>

				<div class="column" ng-if="message.Type == 'Client'">
					<a v-if="message.BSMember.Email" :href="'mailto:' + message.BSMember.Email">{{message.BSMember.Email}}</a>
					<br />
					{{message.BSMember.Phone}}
				</div>
			</div>
			<hr id="messageHr" />
			<div>
				<p class="title is-4">{{ message.Title }}</p>
				<p v-html="message.Message"></p>
			</div>
		</b-message>
	</div>
</template>

<script>
	import axios from "axios";
	export default {
		name: "message",
		props: {
			message: {},
			bsMember: {}
		},
		data() {
			return {

			}
		},
		methods: {
			async deleteMessage() {
				await axios.post("api/message/softDelete", [this.message.Id])
					.then(resp => {
						if (resp.Data) {
							this.$buefy.toast.open({
								message: "Successfully Deleted.",
								duration: 8000
							});
							this.$router.push("/inbox");
						}
					}).catch(err => {
						this.$buefy.toast.open({
							message: "An unhandled Exception Occurred. Please try again later.",
							type: "is-danger",
							duration: 8000
						})
					})
			},
			showInbox() {
				this.$parent.list = true;
				this.$emit('refreshInbox');
			}
		}
	}
</script>

<style>
	#messageContainer p {
		font-family: 'Lato' !important;
	}

	#messageContainer hr {
		background-color: black !important;
	}
</style>
