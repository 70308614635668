<template>
    <div class="section">


        <div id="contactProfile"
             class="container is-widescreen">
            <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
            <br />
            <b-button @click="goToDirectory" type="is-light" class="level-left">
                Back To Search
            </b-button><br /><br />

            <div v-if="contact.PhotoSrc">
                <img id="userPhoto" :src="$APIURL + contact.PhotoSrc" style="height: 150px;" /><br />
            </div>
            <div v-if="!contact.PhotoSrc">
                <img id="userPhoto" src="@/assets/logos/silhouette.jpg" style="height: 150px" />
            </div>
            <h1>{{ contact.FirstName }} {{ contact.LastName }}</h1>
            <br />
            <br />
            <div>
                <div class="level is-mobile" style="padding-bottom: 20px;">
                    <div class="level-left">
                        <div class="level-item" v-if="contact.LinkedIn__c">
                            <b-button class="blue-button" @click="goToLinkedIn(contact.LinkedIn__c)"><img src="@/assets/logos/white-linkedin-logo.png" style="max-height: 20px;" />&nbsp;&nbsp;Follow on LinkedIn</b-button>
                        </div>
                        <div class="level-item" v-if="contact.Award__c" id="awardBlue">
                            <i class="fas fa-award" style="max-height: 50px;"></i>
                        </div>
                        <div class="level-item" v-if="contact.Award__c" id="awardBlue">
                            <a href="https://www.aesc.org/archive/aesc-award-recipients" target="_blank" class="is-vcentered">AESC Award Recipient</a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="contact.Title">
                <p class="has-text-weight-bold">Title</p>
                <p>{{ contact.Title }}</p>
                <br />
            </div>
            <div v-if="account">
                <p class="has-text-weight-bold">Firm</p>
                <p>{{ account.Name }}</p>
                <br />
                <p class="has-text-weight-bold">Office Address</p>
                <div v-if="account.Mailing">
                    <p>{{ account.Mailing.Street }}</p>
                    <p>{{ account.Mailing.City }}, {{ account.Mailing.State }} {{ account.Mailing.Zip }}</p>
                    <p>{{ account.Mailing.Country }}</p>
                </div>
                <br />
            </div>
            <div v-if="contact.Email">
                <p class="has-text-weight-bold">Email</p>
                <a :href="'mailto:' + contact.Email + '?subject=Referral%20by%20AESC'">{{ contact.Email }}</a><br />
                <br />
            </div>
            <div v-if="$store.getters['security/login'] && $store.getters['security/login'].user.userType == 'client'">
                <b-button class="blue-button" @click="sendMessage">Contact Consulant</b-button>
                <br />
                <br />
            </div>
            <h4 class="title is-4 has-text-weight-bold">OFFICE</h4>
            <div v-if="contact.Account">
                <div class="column is-half" @click="goToAccountProfile(contact.Account.Id)">
                    <accountResult style="cursor: pointer;" :account="contact.Account" />
                </div>
                <br />
            </div>

            <div v-if="contact.Bio__c">
                <h4 class="has-text-weight-bold is-uppercase title is-4">About {{ contact.Salutation }} {{ contact.LastName }}</h4>
                <p v-html="contact.Bio__c"></p><br />
            </div>

            <div v-if="contact.Industries">
                <h4 class="has-text-weight-bold title is-4">INDUSTRY SPECIALTIES</h4>
                <p v-for="i in contact.Industries">{{ i }}</p><br />
            </div>
            <br />
            <div v-if="contact.Functions">
                <h4 class="has-text-weight-bold title is-4">FUNCTIONAL SPECIALTIES</h4>
                <p v-for="f in contact.Functions">{{ f }}</p>
            </div>
            <br />
            <div v-if="contact.Services">
                <h4 class="has-text-weight-bold title is-4">SERVICES</h4>
                <p v-for="f in contact.Services">{{ f }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import accountResult from "@/components/directory/search/accountResult.vue";
    import sendMessage from "@/components/directory/profile/sendMessage.vue";
    export default {
        name: "ContactProfile",
        components: {
            accountResult
        },
        props: {
            id: String
        },
        data() {
            return {
                contact: {},
                account: {},
                isLoading: false
            }
        },
        async created() {
            this.isLoading = true;
            await axios.post("api/contacts/directory/" + this.id)
                .then(resp => {
                    this.contact = resp.data;
                }).catch(err => {
                    this.err = err;
                });

            this.account = this.contact.Account;
            this.isLoading = false;
        },
        methods: {
            goToAccountProfile: function (accountId) {
                this.$router.push({ name: 'accountProfile', params: { id: accountId } });
            },
            goToDirectory() {
                window.location.href = "/directory";
            },
            sendMessage() {
                this.$buefy.modal.open({
                    parent: this,
                    component: sendMessage,
                    hasModalCard: true,
                    props: {
                        contact: this.contact,
                    },
                })
            },
            goToLinkedIn(link) {
                window.open(link, '_blank');
            }
        }
    }
</script>

<style>
    #contactProfile {
        text-align: left;
    }

    #awardBlue {
        color: #12609d !important;
    }

        #awardBlue a {
            color: #12609d !important;
        }

            #awardBlue a:hover {
                color: #12609d !important;
                text-decoration: underline;
            }
</style>