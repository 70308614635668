<template>
	<div>
		<div class="is-hidden-desktop">
			<div class="section">
				<div v-if="!isLoggedIn">
					<nav-signin></nav-signin>
				</div>
				<div v-if="isLoggedIn">
					<div class="has-text-centered container">
						<b-dropdown aria-role="list" hoverable expanded>
							<div slot="trigger">
								<button id="my-aesc-btn">
									<i class="fas fa-user" style="margin-right: 8px"></i>
									MY AESC
									<i class="fas fa-sort-down" style="margin-left: 8px"></i>
								</button>
							</div>
							<b-dropdown-item aria-role="menuitem" href="/member/dashboard">
								My Dashboard
							</b-dropdown-item>
							<b-dropdown-item aria-role="menuitem">
								<router-link to="/inbox">
									My Inbox
									<span v-if="unreadMail > 0" class="has-text-weight-bold">({{ unreadMail }})</span>
								</router-link>
							</b-dropdown-item>
							<b-dropdown-item aria-role="menuitem" href="/profile">
								Edit My Profile
							</b-dropdown-item>
							<b-dropdown-item
								v-show="$store.getters['security/isAdmin']"
								aria-role="menuitem"
								href="/manage/user">
								User Admin
							</b-dropdown-item>
							<b-dropdown-item aria-role="menuitem" href="member/membership/my-membership">
								My Membership
							</b-dropdown-item>
							<b-dropdown-item separator></b-dropdown-item>
							<b-dropdown-item aria-role="menuitem" @click="logout">Logout</b-dropdown-item>
						</b-dropdown>
					</div>
				</div>
				<div style="margin-top: 20px">
					<div class="level is-mobile">
						<div class="level-left">
							<a href="/" target="_blank">
								<img src="@/assets/logos/aesc-logo.png" />
							</a>
						</div>
						<div class="level-right">
							<img @click="dropdownActive = !dropdownActive" src="@/assets/logos/aesc-burger.png" />
						</div>
					</div>
				</div>
			</div>
			<div v-if="dropdownActive" id="collapsenav">
				<a
					id="brand"
					role="button"
					href="https://www.aesc.org"
					class="navbar-item card-header card-header-title">
					HOME
				</a>
				<b-collapse :open="false" class="card" animation="slide" aria-id="ABOUT">
					<template #trigger="props">
						<div class="card-header" role="button" aria-controls="ABOUT">
							<p class="card-header-title">ABOUT</p>
							<a class="card-header-icon">
								<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
							</a>
						</div>
					</template>
					<div>
						<div class="navbar-dropdown">
							<a href="/about-us/about-aesc" class="navbar-item">About AESC</a>
							<a href="/about-us/our-standards" class="navbar-item">Our Standards</a>
							<a href="/about-us/board-councils" class="navbar-item">Governance</a>
							<a href="/about-us/partners/about-aesc-partner-program" class="navbar-item">
								Partners
							</a>
							<a href="/contact-us/aesc-staff-directory" class="navbar-item">Contact</a>
						</div>
					</div>
				</b-collapse>
				<b-collapse :open="false" class="card" animation="slide" aria-id="THEPROFESSION">
					<template #trigger="props">
						<div class="card-header" role="button" aria-controls="THEPROFESSION">
							<p class="card-header-title">THE PROFESSION</p>
							<a class="card-header-icon">
								<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
							</a>
						</div>
					</template>
					<div>
						<div class="navbar-dropdown">
							<a href="/profession/overview" class="navbar-item">Overview</a>
							<a href="/profession/executive-talent-solutions" class="navbar-item">Solutions</a>
							<a href="/profession/why-aesc" class="navbar-item">Why AESC</a>
							<a href="/profession/diversity-inclusion" class="navbar-item">
								Diversity &amp; Inclusion
							</a>
							<a href="/aesc-job-board" class="navbar-item">Job Board</a>
						</div>
					</div>
				</b-collapse>
				<b-collapse :open="false" class="card" animation="slide" aria-id="MEMBERSHIP">
					<template #trigger="props">
						<div class="card-header" role="button" aria-controls="MEMBERSHIP">
							<p class="card-header-title">MEMBERSHIP</p>
							<a class="card-header-icon">
								<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
							</a>
						</div>
					</template>
					<div>
						<div class="navbar-dropdown">
							<a href="/members" class="navbar-item">AESC Members</a>
							<a href="/membership/member-benefits" class="navbar-item">Member Benefits</a>
							<a href="/insights/news" class="navbar-item">Member News</a>
							<a href="/membership/prospective-members" class="navbar-item">Prospective Members</a>
						</div>
					</div>
				</b-collapse>
				<b-collapse :open="false" class="card" animation="slide" aria-id="INSIGHTS">
					<template #trigger="props">
						<div class="card-header" role="button" aria-controls="INSIGHTS">
							<p class="card-header-title">INSIGHTS</p>
							<a class="card-header-icon">
								<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
							</a>
						</div>
					</template>
					<div>
						<div class="navbar-dropdown">
							<a href="/insights/aesc-resources" class="navbar-item">AESC Resources</a>
							<a href="/media-gallery" class="navbar-item">Media Gallery</a>
							<a href="/insights/magazine" class="navbar-item">Executive Talent Magazine</a>
							<a href="/insights/blog" class="navbar-item">Executive Talent Insider</a>
							<a href="/insights/thought-leadership" class="navbar-item">Thought Leadership</a>
						</div>
					</div>
				</b-collapse>
				<b-collapse :open="false" class="card" animation="slide" aria-id="PROGRAMS">
					<template #trigger="props">
						<div class="card-header" role="button" aria-controls="PROGRAMS">
							<p class="card-header-title">PROGRAMS</p>
							<a class="card-header-icon">
								<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
							</a>
						</div>
					</template>
					<div>
						<div class="navbar-dropdown">
							<a href="/events" class="navbar-item">Events</a>
							<a href="/education" class="navbar-item">Education</a>
							<a href="/aesc-awards" class="navbar-item">AESC Awards</a>
						</div>
					</div>
				</b-collapse>
				<b-collapse :open="false" class="card" animation="slide" aria-id="EXECUTIVE CAREERS">
					<template #trigger="props">
						<div class="card-header" role="button" aria-controls="EXECUTIVE CAREERS">
							<p class="card-header-title">EXECUTIVE CAREERS</p>
							<a class="card-header-icon">
								<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
							</a>
						</div>
					</template>
					<div>
						<div class="navbar-dropdown">
							<a href="/profession/bluesteps-candidates" class="navbar-item">
								BlueSteps for Executives
							</a>
							<a href="/membership/bluesteps-aesc-members" class="navbar-item">
								BlueSteps for AESC Members
							</a>
						</div>
					</div>
				</b-collapse>
			</div>
			<br />
		</div>
		<div class="is-hidden-touch" id="navigationHeader">
			<div id="topNav" class="container">
				<div id="main">
					<div id="aescImage">
						<div class="columns">
							<div class="column is-8">
								<div style="max-width: 378px; margin-top: 20px">
									<a href="/" target="_blank">
										<img src="@/assets/logos/aesc_logo_2019.png" />
									</a>
								</div>
							</div>
							<div class="column is-4 has-text-right">
								<div v-if="!isLoggedIn" id="notLoggedIn">
									<nav-signin></nav-signin>
								</div>
								<div v-else>
									<div class="has-text-right" id="topRightNav">
										<div class="columns">
											<!-- <div v-if="!isLoggedIn" id="notLoggedIn" class="column">
								<nav-signin></nav-signin>
							</div> -->
											<div
												v-if="isLoggedIn && !$store.getters['security/canSeeDropdown']"
												class="column">
												<div>
													<b-button class="is-light" @click="logout">Logout</b-button>
												</div>
											</div>
											<div
												v-if="isLoggedIn && $store.getters['security/canSeeDropdown']"
												class="column">
												<div>
													<b-dropdown class="is-marginless" aria-role="menu">
														<a class="navbar-item" slot="trigger" role="button">
															<b-icon icon="user"></b-icon>
															<span style="padding-left: 5px; padding-right: 5px">MY AESC</span>
															<i class="fas fa-sort-down" style="padding-bottom: 8px"></i>
														</a>
														<b-dropdown-item aria-role="menuitem" href="/member/dashboard">
															My Dashboard
														</b-dropdown-item>
														<b-dropdown-item aria-role="menuitem">
															<router-link to="/inbox">
																My Inbox
																<span v-if="unreadMail > 0" class="has-text-weight-bold">
																	({{ unreadMail }})
																</span>
															</router-link>
														</b-dropdown-item>
														<b-dropdown-item aria-role="menuitem" href="/profile">
															Edit My Profile
														</b-dropdown-item>
														<b-dropdown-item
															v-show="$store.getters['security/isAdmin']"
															aria-role="menuitem"
															href="/manage/user">
															User Admin
														</b-dropdown-item>
														<b-dropdown-item
															aria-role="menuitem"
															href="member/membership/my-membership">
															My Membership
														</b-dropdown-item>
														<b-dropdown-item separator></b-dropdown-item>
														<b-dropdown-item aria-role="menuitem" @click="logout">
															Logout
														</b-dropdown-item>
													</b-dropdown>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="navigation">
				<div class="container is-uppercase">
					<b-navbar :mobile-burger="true">
						<template slot="start">
							<b-navbar-item id="brand" href="https://www.aesc.org">HOME</b-navbar-item>
							<b-navbar-dropdown label="ABOUT">
								<b-navbar-item href="/about-us/about-aesc">About AESC</b-navbar-item>
								<b-navbar-item href="/about-us/our-standards">Our Standards</b-navbar-item>
								<b-navbar-item href="/about-us/board-councils">Governance</b-navbar-item>
								<b-navbar-item href="/about-us/partners/about-aesc-partner-program">
									Partners
								</b-navbar-item>
								<b-navbar-item href="/contact-us/aesc-staff-directory">Contact</b-navbar-item>
							</b-navbar-dropdown>
							<b-navbar-dropdown label="THE PROFESSION">
								<b-navbar-item href="/profession/overview">Overview</b-navbar-item>
								<b-navbar-item href="/profession/executive-talent-solutions">
									Solutions
								</b-navbar-item>
								<b-navbar-item href="/profession/why-aesc">Why AESC</b-navbar-item>
								<b-navbar-item href="/profession/diversity-inclusion">
									Diversity & Inclusion
								</b-navbar-item>
								<b-navbar-item href="/aesc-job-board">Job Board</b-navbar-item>
							</b-navbar-dropdown>
							<b-navbar-dropdown label="MEMBERSHIP">
								<b-navbar-item tag="router-link" :to="{ path: '/members' }">
									AESC Members
								</b-navbar-item>
								<b-navbar-item href="/membership/member-benefits">Member Benefits</b-navbar-item>
								<b-navbar-item href="/insights/news">Member News</b-navbar-item>
								<b-navbar-item href="/membership/prospective-members">
									Prospective Members
								</b-navbar-item>
							</b-navbar-dropdown>
							<b-navbar-dropdown label="INSIGHTS">
								<b-navbar-item href="/insights/aesc-resources">AESC Resources</b-navbar-item>
								<b-navbar-item href="/media-gallery">Media Gallery</b-navbar-item>
								<b-navbar-item href="/insights/magazine">Executive Talent Magazine</b-navbar-item>
								<b-navbar-item href="/insights/blog">Executive Talent Insider</b-navbar-item>
								<b-navbar-item href="/insights/thought-leadership">
									Thought Leadership
								</b-navbar-item>
							</b-navbar-dropdown>
							<b-navbar-dropdown label="PROGRAMS">
								<b-navbar-item href="/events">Events</b-navbar-item>
								<b-navbar-item href="/education">Education</b-navbar-item>
								<b-navbar-item href="/aesc-awards">AESC Awards</b-navbar-item>
							</b-navbar-dropdown>
							<b-navbar-dropdown label="Executive Careers">
								<b-navbar-item href="/profession/bluesteps-candidates">
									BlueSteps for Executives
								</b-navbar-item>
								<b-navbar-item href="/membership/bluesteps-aesc-members">
									BlueSteps for AESC Members
								</b-navbar-item>
							</b-navbar-dropdown>
						</template>
						<!-- <template slot="end">
							<b-navbar-item class="is-hidden-mobile" href="http://www.linkedin.com/company/aesc">
								<img src="@/assets/logos/linkedInIcon2017.png" alt="linkedin" />
							</b-navbar-item>
							<b-navbar-item class="is-hidden-mobile" href="http://twitter.com/TheAESC">
								<img src="@/assets/logos/twitterIcon2017.png" alt="twitter" />
							</b-navbar-item>
							<b-navbar-item
								class="is-hidden-mobile"
								href="https://www.youtube.com/user/AESCExecutiveSearch/?sub_confirmation=1">
								<img src="@/assets/logos/youtubeIcon2019.png" alt="youtube" />
							</b-navbar-item>
							<b-navbar-item
								class="is-hidden-mobile"
								href="http://www.facebook.com/AssociationExecutiveSearch">
								<img src="@/assets/logos/facebookIcon2017.png" alt="facebook" />
							</b-navbar-item>
							<b-navbar-item
								class="is-hidden-mobile"
								href="https://www.instagram.com/theaesc/?hl=en">
								<img src="@/assets/logos/igIcon2019.png" alt="instagram" />
							</b-navbar-item>
						</template> -->
					</b-navbar>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import NavSignIn from "@/components/navigation/navSignIn.vue";
	export default {
		name: "navigationHeader",
		components: {
			"nav-signin": NavSignIn
		},
		data() {
			return {
				active: false,
				dropdownActive: false
			};
		},
		computed: {
			isLoggedIn: function () {
				return this.$store.getters["security/isLoggedIn"];
			},
			unreadMail: function () {
				return this.$store.getters["security/unreadMessages"];
			},
			canSeeDropdown: function () {
				return this.$store.getters["security/canSeeDropdown"];
			}
		},
		methods: {
			async logout() {
				await this.$store.dispatch("security/logout");
			},
			goHome() {
				window.open("/", "_blank");
			},
			goToLogin() {
				window.open("/login", "_blank");
			}
		}
	};
</script>

<style>
	#collapsenav .card {
		background-color: #9a775d !important;
		color: white !important;
	}

	#collapsenav .card .navbar-item {
		color: white !important;
	}

	#collapsenav .card-header-title {
		color: white !important;
	}

	#my-aesc-btn {
		width: 100%;
		padding: 10px;
		text-align: center;
		background-color: #12609d;
		display: inline-block;
		min-width: 190px;
		color: #fff;
		text-transform: uppercase;
		border-radius: 0;
		border: 0px;
		box-shadow: none;
		cursor: pointer;
	}

	#navigation .navbar-menu {
		max-height: 40px !important;
		background-color: #9a775d;
	}

	#navigationHeader #main {
		padding-bottom: 20px;
	}

	#topNav {
		padding-top: 15px;
	}

	#topRightNav a {
		color: black;
		text-decoration: none;
	}

	#topRightNav a:hover {
		text-decoration: underline;
	}

	#navigation {
		background-color: #9a775d;
		color: white;
		max-height: 40px;
	}

	#notLoggedIn a {
		font-size: 15px;
	}

	#navigationHeader nav {
		font-size: 14px;
		color: white !important;
		max-height: 40px !important;
		min-height: 40px !important;
	}

	#navigationHeader nav .navbar {
		max-height: 40px !important;
	}

	.nav-item img {
		height: 30px;
	}

	#navImg {
		padding-top: 7.5px;
		padding-left: 5px;
		padding-right: 5px;
	}

	.dropdown-menu {
		z-index: 40 !important;
	}

	.navbar a {
		color: white !important;
		text-decoration: none;
		transition: 0.4s;
	}

	.navbar-dropdown {
		color: white !important;
		background-color: #12609d !important;
		transition: 0s;
		padding: 0px !important;
		border: none !important;
		border-radius: 0px !important;
	}

	.navbar-dropdown a:hover {
		color: black !important;
		background-color: white !important;
	}

	#brand {
		background-color: #9a775d !important;
	}

	#brand:hover {
		background-color: #12609d !important;
		color: white !important;
	}

	.navbar-link {
		background-color: #9a775d !important;
	}

	.dropdown a {
		text-decoration: none !important;
	}

	.navbar-link:hover {
		background-color: #12609d !important;
		color: white !important;
	}

	.navbar-link:not(.is-arrowless)::after {
		border-color: white !important;
	}

	.navbar-end a:hover {
		background-color: #12609d !important;
		color: white !important;
	}

	.navbar-end a {
		background-color: #9a775d !important;
		color: white !important;
	}

	.navbar-end img {
		max-width: 22px;
	}

	#hiddenButton {
		background-color: #9a775d !important;
		border-color: #9a775d !important;
		color: white;
	}

	.center-right-align-column {
		display: flex !important;
		align-items: end;
		flex-direction: row-reverse;
	}
</style>
