<template>
    <div class="section">
        <div id="searchFirm" class="container has-text-left">
            <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
            <div v-bind:class="{'blurOverlay': isLoading }">
                <div class="search-firm-banner is-hidden-mobile">
                    <img class="img-responsive" style="height: 200px;" src="@/assets/logos/search-firm-banner.jpg" />
                    <div class="columns search-firm-banner-content">
                        <div class="column is-9">
                            <div style="margin: 25px 0 15px 0px;" class="search-firm-banner-header is-uppercase">{{ account.Name }}</div>
                            <div style="margin: 5px 0 15px 0;" class="size-down">
                                <img src="@/assets/logos/hexagon-checkmark.png" style="height: 30px; margin-bottom: -10px" /> AESC QUALITY CERTIFIED
                                <br /><a class="has-text-white is-size-7" :href="account.Website">{{ account.Website }}</a>
                            </div>
                        </div>
                        <div class="column is-3">
                            <div class="search-firm-banner-bright size-down" style="text-align: right; margin-top: 25px">
                                <p class="has-text-black">What to expect from an AESC Member</p>
                                <a class="has-text-black" href="https://aesc.org/insights/research/global-guide-choosing-executive-search-firm">Get the Guide ></a>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <router-link class="link-color" to="/members">Back to Search</router-link>

                <div id="about" class="columns">
                    <div class="column">
                        <h4 class="title is-4">About {{ account.Name }}</h4>
                        <p>Number of Consultants at this {{ account.ParentName != null ? 'Office': 'Firm' }}: {{ account.Consultants }}</p>
                        <div v-if="account.ParentName != null">
                            <p><a class="link-color" @click="loadParent(account.ParentName)">To Firm Profile</a></p>
                        </div>
                    </div>
                    <div class="column has-text-right is-hidden-touch">
                        <p><b-button class="level-item red-button cornered is-pulled-right" @click="goToRegistration">CONNECT WITH A CONSULTANT</b-button></p><br /><br />
                        <p>Register / Login to connect with AESC Member consultants who can help your organization find, attract and develop top executive-level talent.</p>
                    </div>
                    <div class="column has-text-left is-hidden-desktop">
                        <p><b-button class="level-item red-button cornered is-pulled-left" @click="goToRegistration">CONNECT WITH A CONSULTANT</b-button></p><br /><br />
                        <p>Register / Login to connect with AESC Member consultants who can help your organization find, attract and develop top executive-level talent.</p>
                    </div>
                </div>
                <div v-html="account.Bio">
                </div>
                <br />
                <div style="font-size: 14px;" v-if="industries && industries.length > 0">
                    <div class="slanted-header"><span class="title is-6 skewed-header">Industry Specialties</span></div><br />
                    <div id="industrySpecialties" class="columns is-multiline content">
                        <div class="column is-4 list-columns" v-for="i in industries">
                            <ul class="list-ul">
                                <li>{{ i }}</li>
                            </ul>
                        </div>
                    </div>
                    <br />
                </div>
                <div style="font-size: 14px;" v-if="functions && functions.length > 0">
                    <div class="slanted-header"><span class="title is-6 skewed-header">Functional Specialties</span></div><br />
                    <div id="functionalSpecialties" class="columns is-multiline content">
                        <div class="column is-4 list-columns" v-for="f in functions">
                            <ul class="list-ul">
                                <li>{{ f }}</li>
                            </ul>
                        </div>
                    </div>
                    <br />
                </div>
                <div style="font-size: 14px;" v-if="services && services.length > 0">
                    <div class="slanted-header"><span class="title is-6 skewed-header">Services</span></div><br />
                    <div id="services" class="columns is-multiline content">
                        <div class="column is-4 list-columns" v-for="s in services">
                            <ul class="list-ul">
                                <li>{{ s }}</li>
                            </ul>
                        </div>
                    </div>
                    <br />
                </div>
                <div style="font-size: 14px;" v-if="locations && locations.length > 0">
                    <div class="slanted-header"><span class="title is-6 skewed-header">Locations</span></div><br />
                    <div id="locations" class="columns is-multiline content">
                        <div class="column is-4 list-columns" v-for="l in locations">
                            <ul class="list-ul">
                                <li>{{ l }}</li>
                            </ul>
                        </div>
                    </div>
                    <br />
                </div>
                <div id="theAescStandard" class="content">
                    <h4 class="title is-4">The AESC Standard</h4>
                    <p id="space">
                        {{ account.Name }} commits to AESC's rigorous Code of Professional Practice.
                        Business leaders worldwide can be assured that AESC Members are able to serve as trusted advisors for their most important engagements.
                    </p>
                    <p>
                        CEOs, CHROs and Board of Directors are invited to register to access the AESC Member Directory
                        and connect with vetted expert consultants at {{account.Name}} and all AESC member firms. <a class="link-color" @click="goToRegistration">Connect with a consultant now ></a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "searchFirm",
        props: {
            name: String
        },
        head: {
            meta: [
                { name: 'description', content: '<%=Description50Words %>' },
                { property: 'og:site_name', content: 'AESC' },
                { property: 'og:type', content: 'article' },
                { property: 'og:url', content: 'https://www.aesc.org/search-firm/<%=Firm.Name_UrlSafe %>' },
                { property: 'og:title', content: '<%=Firm.Name %>' },
                { property: 'og:description', content: '<%=Description50Words %>' },
            ]
        },
        data() {
            return {
                account: {},
                industries: [],
                functions: [],
                locations: [],
                services: [],
                err: '',
                isLoading: true
            }
        },
        async created() {
            this.isLoading = true;

            await axios.get("api/accounts/name/" + this.name)
                .then(resp => {
                    if (resp.data == null || resp.data == "") {
                        this.$buefy.toast.open("The Firm/Office you have tried to reach cannot be displayed.");
                        this.$router.push("/members");
                    }
                    this.account = resp.data;
                    this.industries = this.account.IndustrySpecialties != null ? this.account.IndustrySpecialties.sort() : null;
                    this.functions = this.account.FunctionalSpecialties != null ? this.account.FunctionalSpecialties.sort() : null;
                    this.locations = this.account.Countries != null ? this.account.Countries.sort() : null;
                    this.services = this.account.Services != null ? this.account.Services.sort() : null;
                    this.isLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.err = err;
                });
        },
        methods: {
            goToRegistration: function () {
                this.$router.push({ name: 'clientRegistration' });
            },
            async loadParent(parentName) {
                window.location.href = "/search-firm/" + parentName;
            }
        }
    }
</script>

<style>
    #about {
        padding-top: 30px;
    }

    #searchFirm a {
        color: dodgerblue;
    }

        #searchFirm a:hover {
            text-decoration: underline;
        }

    .red-button {
        background-color: rgb(93, 31, 64) !important;
        color: white !important;
    }

    #searchFirm h4 {
        color: rgb(93, 31, 64) !important;
    }

    #space {
        padding-bottom: 20px;
    }

    .slanted-header {
        color: black;
        background-color: #d1dee8;
        transform: skew(-20deg);
        padding: 5px 24px 5px 24px;
        white-space: nowrap;
        margin: 10px 0 10px 0;
        width: 225px;
    }

    .skewed-header {
        transform: skew(20deg);
        display: block;
    }

    .img-responsive {
        display: block;
        max-width: 100%;
        height: auto;
    }

    .search-firm-banner {
        background-color: #f5f4f0;
        margin-bottom: 10px;
    }

    .search-firm-banner-content {
        color: white;
        padding: 5px 20px 5px 20px;
        position: absolute;
        top: 0 !important;
        font-family: Arial;
        width: 100%;
    }

    .search-firm-banner-header {
        font-size: 36px;
        line-height: 1.0;
        transition: font-size 1s linear;
    }

    .list-columns {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .list-ul {
        margin-top: 0px !important;
    }

    .blurOverlay {
        filter: blur(5px) !important;
    }

    .link-color {
        color: #12609D !important;
    }
</style>