<template>
	<div class="section">
		<div id="searchFirms" class="container has-text-left">
			<h1>AESC Member Firms</h1>

			<b-button @click="goToPublicDirectory" type="is-primary">Use Search Filters</b-button>

			<div id="lists" class="content" v-for="(accounts, index) in accountGroups">
				<h2>{{ index }}</h2>
				<ul>
					<li v-for="account in accounts">
						<router-link :to="{ name: 'searchFirm', params: { name: account.NameUrlSafe }  }">{{account.Name}}</router-link>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
    import axios from "axios";
    export default {
        name: "searchFirms",
        data() {
            return {
                accountGroups: {},
                err: ''
            }
        },
        created: async function () {
            await axios.get("api/accounts/public")
                .then(resp => {
                    this.accountGroups = resp.data;
                }).catch(err => {
                    this.err = err;
                });

        },
        methods: {
            goToPublicDirectory() {
                this.$router.push({ path: "/members" });
            }
        }
    }
</script>

<style>
	#searchFirms a {
		color: steelblue;
	}

	#searchFirms li {
		font-size: 14px;
	}

	#lists {
		padding: 20px 0 0 0;
	}

		#lists ul {
			margin-top: 0px;
		}

		#lists.title {
			margin: 0px;
		}


	#title {
		color: rgb(93, 31, 64) !important;
	}
</style>
