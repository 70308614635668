<template>
	<div id="accountResult" class="is-full-height">
		<p id="name" class="is-uppercase">
			{{ account.Name }}
		</p>
		<a :href="account.Website" target="_blank">{{ account.Website }}</a>
	</div>
</template>

<script>
	export default {
		name: "accountResult",
		props: {
			account: {}
		},
		data() {
			return {
			}
		}
	}
</script>

<style>
	#accountResult {
		background-color: white !important;
		border-color: #dbdbdb !important;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.3) !important;
		border: none !important;
		transition: box-shadow linear .2s !important;
		padding: 15px !important;
	}

		#accountResult:hover {
			box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4), 0 12px 40px 0 rgba(0, 0, 0, 0.3) !important;
		}
</style>
