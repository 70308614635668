<template>
	<div id="memberSearch">
		<div class="banner is-hidden-mobile" id="directoryBanner" style="color: white">
			<div style="position: absolute; width: 100%;">
				<div class="container" style="overflow: auto;">
					<div style="margin-top: 40px;">
						<p style="font-size: 28px;">WORK WITH AN AESC MEMBER</p>
						<p class="text1" style="max-width: 50%;">
							Quality search firms know the talent business and take the time to know your business.
							AESC Members are vetted, trusted professionals that leverage their access and expertise to place,
							find and develop executives in board and C-level postions for leading organizations of all types and sizes.
						</p>
					</div>
				</div>
			</div>
			<div class="is-hidden-touch" style="position: absolute; bottom: 10px; background: rgba(18,25,33,.5); font-size: 24px; padding: 20px; width: 100%; text-align: center;">
				16,000+ TRUSTED PROFESSIONALS | 1,200+ OFFICES | 70+ COUNTRIES
			</div>
			<img src="@/assets/logos/membersLandingPage_banner.jpg" />
		</div>
		<div class="section">
			<div class="container">
				<div class="columns is-centered">
					<div class="column is-5 has-text-centered">
						<b-button class="red-button" style="border-radius: 0px;" @click="goToRegistration">CONNECT WITH A CONSULTANT</b-button>
						<br /><br />
						<h3>Find an AESC vetted firm for your assignment.</h3>
					</div>
				</div>
				<b-message title=" " :closable="false" id="searchArea">
					<div class="columns">
						<div class="column is-half">
							<b-field horizontal label="Industry">
								<b-select placeholder="Select an Industry"
										  v-model="query.Industry"
										  expanded>
									<option value="">Select an Industry</option>
									<option v-for="option in industries"
											:value="option"
											:key="option">
										{{ option }}
									</option>
								</b-select>
							</b-field>
							<b-field horizontal label="Function">
								<b-select placeholder="Select a Function"
										  v-model="query.Function"
										  expanded>
									<option value="">Select a Function</option>
									<option v-for="option in functions"
											:value="option"
											:key="option">
										{{ option }}
									</option>
								</b-select>
							</b-field>
                            <b-field horizontal label="Service">
                                <b-select placeholder="Select a Service"
                                          v-model="query.Service"
                                          expanded>
                                    <option value="">Select a Service</option>
                                    <option v-for="option in services"
                                            :value="option"
                                            :key="option">
                                        {{ option }}
                                    </option>
                                </b-select>
                            </b-field>
							<b-field horizontal label="Firm">
								<b-select placeholder="Select a Firm"
										  v-model="query.AccountObj"
										  expanded>
									<option value="">Select a Firm</option>
									<option v-for="option in accounts"
											:value="option"
											:key="option.Id">
										{{ option.Name }}
									</option>
								</b-select>
							</b-field>
						</div>
						<div class="column is-half">
							<b-field horizontal label="Region">
								<b-select placeholder="Select a Region"
										  v-model="query.Region"
										  expanded>
									<option value="">Select a Region</option>
									<option v-for="option in regions"
											:value="option"
											:key="option">
										{{ option }}
									</option>
								</b-select>
							</b-field>
							<b-field horizontal label="Country">
								<b-select placeholder="Select a Country"
										  v-model="query.Country"
										  expanded>
									<option value="">Select a Country</option>
									<option v-for="option in countries"
											:value="option"
											:key="option">
										{{ option }}
									</option>
								</b-select>
							</b-field>
							<b-field horizontal label="City">
								<b-input id="city"
										 v-model="query.City"
										 @keyup.enter.native="search"
										 placeholder="">
								</b-input>
							</b-field>
						</div>
					</div>
					<div class="columns">
						<div class="column">
							<div class="has-text-centered">
								<b-button class="blue-button"
										  style="border-radius: 0px;"
										  :disabled="searchDisabled"
										  @click="search">
									Search
								</b-button>
							</div>
							<div class="has-text-right clearFilters">
								<a @click="clearSearch">Clear Filters</a>
							</div>
						</div>
					</div>
				</b-message>
				<br />
				<div class="columns is-centered">
					<div class="column is-3 has-text-centered">
						<router-link id="viewAll" class="is-centered" to="/search-firms">VIEW ALL AESC MEMBERS</router-link>
					</div>
				</div>
				<div v-show="searching">
					<span>Searching ... </span><i class="fas fa-sync fa-spin"></i>
				</div>
				<br />
				<div v-if="query.hasBeenSearched">
					<div class="level" v-show="!searching">
                        <p class="level-right" id="contactsFound">{{ searchResp.length }} offices found.</p>
					</div>
				</div>
				<div id="responseResults" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="5">
					<div class="columns is-multiline">
						<div v-for="a in respDisplay"
							 :key="a.Id"
							 class="column is-half">
							<div class="is-full-height"
								 @click="goToPublicAccountProfile(a.Name_UrlSafe)">
                                <a class="card-text" :href="'/search-firm/' + a.Name_UrlSafe">
                                    <accountResult style="cursor: pointer" class="is-full-height" :account="a" />
                                </a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	import accountResult from "@/components/directory/search/accountResult.vue";
	export default {
		name: "memberSearch",
		components: {
			accountResult
		},
		data() {
			return {
				query: {},
				functions: [],
				industries: [],
                services: [],
				accounts: [],
				regions: [],
				countries: [],
				industryTag: '',
				functionTag: '',
				accountTag: '',
				regionTag: '',
				countryTag: '',
				searchResp: [],
				respDisplay: [],
				openOnFocus: true,
				searchDisabled: false,
				searching: false,
			}
		},
		async created() {
			var storeQuery = this.$store.getters["directory/preQuery"];
			console.log(storeQuery);
			if (storeQuery != null && (storeQuery.City || storeQuery.Function || storeQuery.Industry || storeQuery.AccountObj || storeQuery.Region || storeQuery.Country)) {
				this.query = storeQuery;
				this.search();
			} else {
				this.query.Account = false;
				this.query.AccountObj = null;
			}

			await axios
				.get("api/functions/tags")
				.then(resp => {
					this.functions = resp.data;
				})
				.catch(err => {
					this.error = err;
				});

			await axios
                .get("api/services/tags")
                .then(resp => {
                    this.services = resp.data;
                })
                .catch(err => {
                    this.error = err;
                });

            await axios
				.get("api/industries/tags")
				.then(resp => {
					this.industries = resp.data;
				})
				.catch(err => {
					this.error = err;
				});

			await axios
				.get("api/directory/countries")
				.then(resp => {
					this.countries = resp.data;
				})
				.catch(err => {
					this.error = err;
				});

			await axios
				.get("api/directory/regions")
				.then(resp => {
					this.regions = resp.data;
				})
				.catch(err => {
					this.error = err;
				});
			await axios
				.get("api/accounts/filter")
				.then(resp => {
					this.accounts = resp.data;
				})
				.catch(err => {
					this.error = err;
				});
		},
		methods: {
			clearSearch: function () {
				this.query = {};
				this.searchResp = [];
				this.respDisplay = [];
				this.$store.dispatch("directory/updatePostQuery", {});
			},
			loadMore() {
				if (this.searchResp.length - this.respDisplay.length < 20)
					for (var i = this.respDisplay.length; i < this.searchResp.length; i++) {
						if (this.searchResp[i])
							this.respDisplay.push(this.searchResp[i]); // Load the rest of the data
					}
				else {
					for (var i = 0; i < 20; i++) {
						if (this.searchResp[this.respDisplay.length])
							this.respDisplay.push(this.searchResp[this.respDisplay.length]);
					}
				}
			},
			goToPublicAccountProfile: function (accountName) {
                //this.$router.push({ name: 'searchFirm', params: { name: accountName } });
				this.$store.dispatch("directory/updatePreQuery", this.query);
			},
			goToRegistration: function () {
				this.$router.push({ name: 'clientRegistration' });
			},
			search: function () {
                if (this.query.City || this.query.Function || this.query.Industry || this.query.AccountObj || this.query.Region || this.query.Country || this.query.Service) {

					this.searchDisabled = true;
					this.searchResp = [];
					this.respDisplay = [];
					this.error = '';
					if (this.query.AccountObj) {
						this.query.AccountId = this.query.AccountObj.Id;
					} else {
						this.query.AccountId = null;
					}
					this.searching = true;


					axios.post("api/directory/public",
						this.query)
						.then(resp => {
							this.searchResp = resp.data;
							if (this.searchResp.length < 20) {
								for (var i = 0; i < this.searchResp.length; i++) {
									if (this.searchResp[i])
										this.respDisplay.push(this.searchResp[i]); // Load the rest of the data
								}
							} else {
								for (var i = 0; i < 20; i++) {
									this.respDisplay.push(this.searchResp[i]);
								}
							}
							this.searchDisabled = false;
							this.query.hasBeenSearched = true;
							this.searching = false;
						}).catch(err => {
							this.$buefy.toast.open({
								message: "Something went wrong with the search. Please try again later.",
								type: "is-danger",
								duration: 8000
							});
							this.searchDisabled = false;
							this.query.hasBeenSearched = true;
							this.searching = false;
						});
				} else {
					this.respDisplay = [];
					this.query.hasBeenSearched = false;
					this.$buefy.toast.open({
						message: "You Must Enter at Least One Filter to Search",
						type: "is-danger",
						duration: 8000
					});
				}

			}
		},
	}
</script>

<style>
	#responseResults .message {
		border: none;
	}

	#responseResults .message-body {
		border: none;
	}

	#memberSearch .message-header {
		background-color: whitesmoke;
		border-color: #dbdbdb !important;
		border-top: solid;
		border-bottom: solid;
		border-width: 1px 1px 1px 1px;
	}

	#searchArea {
		background-color: white;
		border-color: #dbdbdb;
		border-radius: 4px;
		border-style: solid;
		border-width: 0 1px 1px 1px;
	}

	#viewAll {
		margin-bottom: 20px;
	}

	.banner {
		position: relative;
		margin-bottom: 20px;
	}

	.text1 {
		font-size: 15px;
	}

	#directoryBanner img {
		width: 100%;
		height: 100%;
	}

	.clearFilters {
		color: #3273dc;
	}

		.clearFilters a {
			text-decoration: none !important;
		}
</style>
