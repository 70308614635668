<template>
	<div>
		<div id="registrationBanner" class="registrationBanner hide-banner">
			<div class="columns">
				<div class="column">
					<div style="position: relative;">
						<img class="img-responsive" src="@/assets/logos/clientRegistrationPage_banner.jpg" style="width: 100%" />
						<div class="aesc-img-text">
							Connect with vetted AESC Member consultants who can help your organization find, attract and develop top executive-level talent.
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section">

			<div id="clientRegistration" class="container" style="clear: both;">
				<div class="columns" style=" margin-top: 20px;">
					<div class="column is-6 border-right-desktop">
						<div>
							<h1 style="font-size: 30px;">
								Who Should Register?
							</h1>
							<p>
								CEOs, Board Directors & Senior HR Executives (CHROs, Head of Talent Acquisition, etc.)
							</p>
							<br />
							<h1 style="font-size: 30px;">
								What Registration Provides You:
							</h1>
							<ul style="font-size: 14px; margin-left: 20px">
								<li>Access to AESC's Global Directory of Executive Search and Leadership Consultants</li>
								<li>The opportunity to search AESC vetted consultants by industry, functional and geographic specialty</li>
								<li>Monthly updates about trends and insights impacting today’s leaders</li>
							</ul>
							<div style="margin-top: 12px" class="has-text-left">
								<a href="#clientForm"><b-button class="aesc2019-purple-btn">GET STARTED</b-button></a>
								<div>
									<a style="font-size: 14px;" href="/login">Already have an account? Login.</a>
								</div>
							</div>
						</div>
					</div>
					<div class="column is-6">
						<h2 class="aesc2019-blue">
							Seeking Executive Job Opportunities Instead?
						</h2>
						<p>
							Register with AESC's BlueSteps. Advance Your Career, Track Your Goals and Elevate Your Visibility to the Right Recruiters.
						</p>
						<br />
						<a href="https://www.bluesteps.com/partner/association-of-executive-search-consultants">
							<img style="max-width: 300px;" src="@/assets/logos/blueStepsLogo2019.png" />
						</a>
						<br />
						<b-button class="aesc-bluesteps-btn">
							<a href="https://www.bluesteps.com/partner/association-of-executive-search-consultants" class="btn aesc-bluesteps-btn">GET AHEAD WITH BLUESTEPS</a>
						</b-button>
					</div>
				</div>
				<br />
				<div class="columns">
					<div class="column is-8 is-offset-2" id="clientForm">
						<b-message type="is-info">
							<p style="color: black;">First Name *</p>
							<b-input id="FirstName"
									 required
									 v-model="user.FirstName">
							</b-input>
							<p style="color: black;">Last Name *</p>
							<b-input id="LastName"
									 required
									 v-model="user.LastName">
							</b-input>
							<p style="color: black;">Job Title *</p>
							<b-input id="jobTitle"
									 required
									 v-model="user.JobTitle">
							</b-input>
							<p style="color: black;">I am a(n): *</p>
							<b-select style="margin-bottom: 10px;" placeholder="Select One"
									  required
									  expanded
									  v-model="user.Experience">
								<option value="Professional at an executive search or leadership consulting firm">Professional at an executive search or leadership consulting firm</option>
								<option value="Executive interested in leadership solutions for my organization">Executive interested in leadership solutions for my organization</option>
								<option value="Business leader interested in executive career services for myself">Business leader interested in executive career services for myself</option>
								<option value="Other (e.g., academic, journalist, vendor)">Other (e.g., academic, journalist, vendor)</option>
							</b-select>
							<p style="color: black;">Select an Industry *</p>
							<b-select style="margin-bottom: 10px;" placeholder="Select One"
									  required
									  expanded
									  v-model="user.Industry">
								<option v-for="i in industries" :value="i">{{i}}</option>
							</b-select>
							<p style="color: black;">Company Name *</p>
							<b-input id="CompanyName"
									 required
									 v-model="user.CompanyName">
							</b-input>
							<p style="color: black;">Company Website *</p>
							<b-input id="CompanyWebsite"
									 required
									 v-model="user.CompanyWebsite"
									 placeholder="https://example.com">
							</b-input>
							<div class="field">
								<b-tag v-show="badEmail"
									   type="is-danger"
									   attached>
									{{ badEmailMessage }}
								</b-tag>
							</div>
							<p style="color: black;">Work E-mail Address *</p>
							<b-input id="WorkEmail"
									 @input="checkEmail"
									 type="email"
									 required
									 v-model="user.Email">
							</b-input>
							<p style="color: black;">Select a Country *</p>
							<b-select style="margin-bottom: 10px;" placeholder="Select One"
									  required
									  expanded
									  v-model="user.Country">
								<option v-for="c in countries" :value="c.Id">{{c.Name}}</option>
							</b-select>
							<p style="color: black;">City/State *</p>
							<b-input id="CityState"
									 required
									 v-model="user.CityState">
							</b-input>
							<p style="color: black;">How did you hear about the AESC's service for clients? *</p>
							<b-select style="margin-bottom: 10px;" placeholder="Select One"
									  required
									  expanded
									  multiple
									  v-model="user.ReferrerTags">
								<option v-for="r in referrers" :value="r">{{r}}</option>
							</b-select>
							<p style="color: black;">If Other, please specify:</p>
							<b-input type="text"></b-input>
							<vue-recaptcha sitekey="6LevFrcUAAAAABv7obKQWVKPP1NfOiEGqeD4l1gK" :loadRecaptchaScript="true" @verify="onVerify"></vue-recaptcha>
							<br />
							<p id="awardBlue">By submitting this form you agree to <a target="_blank" href="https://www.aesc.org/about-us/terms-and-conditions">AESC's Terms and Conditions</a></p><br />
							<b-button v-bind:class="{'is-loading': loading == true}" type="is-info" @click="register" :disabled="disabled">Submit</b-button>
						</b-message>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	import VueRecaptcha from 'vue-recaptcha';
	export default {
		name: "clientRegistration",
		components: { VueRecaptcha },
		data() {
			return {
				user: {},
				industries: [],
				countries: [],
				message: '',
				err: '',
				openOnFocus: true,
				filteredReferrers: this.referrers,
				referrers: [
					'Ad',
					'Colleague/Friend',
					'Email',
					'Internet Search',
					'News Article',
					'Trade Show/Conference',
					'Website',
					'Other (specify)'
				],
				captchaResponse: {},
				disabled: true,
				loading: false,
				isTyping: false,
				badEmailMessage: ""
			}
		},
		created: async function () {
			this.filteredReferrers = this.referrers;

			await axios
				.get("api/industries/lead/tags")
				.then(resp => {
					this.industries = resp.data;
				})
				.catch(err => {
					this.error = err;
				});

			await axios
				.get("api/countries")
				.then(resp => {
					this.countries = resp.data;
				})
				.catch(err => {
					this.error = err;
				});
		},
		computed: {
			badEmail() {
				if (this.badEmailMessage == "") {
					return false;
				}
				else if (this.badEmailMessage == "Please enter a valid Email Address") {
					return true;
				}
				else if (this.badEmailMessage == "Not an acceptable domain. Please enter a non-personal email address.") {
					return true;
				}
				else if (this.badEmailMessage == "Valid Email") {
					return false;
				}
				else {
					return false;
				}
			}
		},
		methods: {
			scrollMeTo(refName) {
				var element = this.$refs[refName];
				var top = element.offsetTop;

				window.scrollTo(0, top);
			},
			async checkEmail() {
				await this.$http.get("api/contacts/email/" + this.user.Email)
					.then(resp => {
						this.badEmailMessage = resp.data;
					}).catch(err => {
						console.log(err);
					})
			},
			async register() {
				if (this.badEmail == true) {
					this.$buefy.toast.open({
						message: "Please use a valid, professional/company email address.",
						type: "is-danger",
						duration: 8000
					})
				}
				else if (this.user.ReferrerTags && this.user.CityState && this.user.Email
					&& this.user.CompanyWebsite && this.user.CompanyName && this.user.Industry && this.user.Country
					&& this.user.Experience && this.user.FirstName && this.user.LastName
					&& this.user.JobTitle) {
					this.loading = true;

					await axios
						.post("api/contacts/register", this.user)
						.then(resp => {
							this.message = resp.data;
							this.$buefy.dialog.alert(resp.data);
							this.loading = false;
						})
						.catch(err => {
							this.error = err;
							this.loading = false;
						});
				} else {
					this.$buefy.toast.open({
						message: "Please fill out all fields",
						type: "is-danger",
						duration: 8000
					})
				}
			},
			onVerify(toLog) {
				if (toLog) {
					this.disabled = false;
				}
			}
		}
	}
</script>

<style>
	#clientRegistration ul {
		list-style-type: inherit;
	}

	.img-responsive {
		display: block;
		max-width: 100%;
		height: auto;
	}

	.aesc-img-text {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 12px 90px 12px 90px;
		background-color: rgba(38, 38, 38, .7);
		color: white;
		font-size: 22px;
		text-align: center;
		margin-right: 15px;
	}

	.message.is-info .message-body {
		border-color: rgb(57, 96, 154) !important;
	}

	#clientRegistration input {
		margin-bottom: 15px;
	}

	.aesc2019-blue {
		text-transform: none;
		font-size: 30px;
		border-bottom: none;
		color: #437eb5;
	}

	.aesc2019-purple-btn {
		text-decoration: none !important;
		color: white !important;
		background-color: #6c193e !important;
		border-radius: 0 !important;
		margin: 5px 0px 5px 0px !important;
	}

		.aesc2019-purple-btn:hover, a.btn.aesc2019-purple-btn:focus {
			text-decoration: none !important;
			color: white !important;
		}

	.aesc-bluesteps-btn {
		text-decoration: none !important;
		color: white !important;
		background-color: #0d6cb6 !important;
		text-align: center !important;
		border-radius: 0 !important;
		margin: 5px 0px 5px 0px !important;
	}

		.aesc-bluesteps-btn:hover, .aesc-bluesteps-btn:focus {
			text-decoration: none !important;
			color: white !important;
			background-color: #1062ae !important;
		}
</style>
