<template>
	<div class="section">
		<div class="container">
			<h1>Login</h1>
			<div class="columns">
				<div class="column is-7">
					<b-message>
						<b-field label="Email">
							<b-input id="username"
									 type="email"
									 @keydown.native="catch_Enter($event)"
									 v-model="form.username">
							</b-input>
						</b-field>
						<b-field label="Password">
							<b-input id="password"
									 type="password"
									 @keydown.native="catch_Enter($event)"
									 v-model="form.password">
							</b-input>
						</b-field>
						<b-button class="red-button"
								  :class="{'is-loading': loading}"
								  @click="login">
							Submit
						</b-button>
						<div id="loginExtra">
							<p>
								<router-link to="/forgotPassword">
									Forgot Your Password?
								</router-link>
							</p>
							<p>
								AESC Member?
								<router-link to="/register">
									Create An Account
								</router-link>
							</p>
							<p>
								New Client?
								<router-link to="/clientregistration/register">Create An Account</router-link>
							</p>
						</div>
					</b-message>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Login",
		data() {
			return {
				form: {},
				loading: false
			};
		},
		methods: {
			catch_Enter(e) {
				if (e.keyCode === 13 && !this.loading) {
					this.login();
				}
			},
			async login() {
				if (this.form.username && this.form.password) {
					this.loading = true;
					await this.$http.post('api/security/login', { email: this.form.username, password: this.form.password, sessionId: null })
						.then(async resp => {
							if (resp.data.error == "Invalid Credentials") {
								this.$buefy.toast.open({
									message: "The login credentials were invalid.",
									type: 'is-danger',
									duration: 8000
								});
							}
							else if (resp.data.error == "Invalid Username") {
								this.$buefy.toast.open({
									message: "The username is not in email form.",
									type: 'is-danger',
									duration: 8000
								});
							}
							else if (resp.data.login == true) {
								await localStorage.setItem("AESC_AUTH_TOKEN", resp.data.tokens.authorization);
								await localStorage.setItem("AESC_REFRESH_TOKEN", resp.data.tokens.refresh);
								await this.$store.dispatch("security/handTokensForDemo", { authorization: resp.data.tokens.authorization, refresh: resp.data.tokens.refresh })
									.then(resp => {
										this.$router.push('/dashboard');
									})

								this.$buefy.toast.open({
									message: "You have logged in",
									type: 'is-success',
									duration: 8000
								});

							}
							else if (resp.data === undefined) {
								console.log(resp.Exception);
								this.$buefy.toast.open({
									message: "An unhandled exception occurred.",
									type: 'is-danger',
									duration: 8000
								});
							}
							else if (resp.data.Exception.Message != null && resp.data.Exception.Message === "Username or Password was incorrect.") {
								this.$buefy.toast.open({
									message: "The login credentials were invalid.",
									type: 'is-danger',
									duration: 8000
								});
							}
							else {
								this.$buefy.toast.open({
									message: "There was an issue contacting the server. Please try again in a few minutes.",
									type: 'is-danger',
									duration: 8000
								});
							}
							this.loading = false;
						}).catch(err => {
							this.loading = false;
							console.log(err);
							this.$buefy.toast.open({
								message: "Error while trying to log in. Please try again later.",
								type: 'is-danger',
								duration: 8000
							});
						});
				} else {
					this.$buefy.toast.open({
						message: "Please fill out both Fields.",
						duration: 8000
					});
				}
			},
		}
	};
</script>

<style>
	#loginForm {
		color: black;
		padding-left: 20px;
		padding-right: 20px;
	}

	#submitBtn {
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 10px;
	}

	#loginExtra {
		padding: 20px 0;
	}

		#loginExtra p {
			margin: 10px 0;
		}

		#loginExtra a {
			color: royalblue;
		}

		#loginExtra p {
			color: black;
		}
</style>
