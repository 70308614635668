<template>
	<div id="contactResult" class="is-full-height" style="font-family: 'Lato'">
		<div class="columns">
			<div class="column is-9">
				<p v-if="contact.FirstName == null"
				   id="name"
				   style="font-family: 'Lato'"
				   class="info is-uppercase">
					{{ contact.Name }}
				<p id="name"
				   style="font-family: 'Lato'"
				   class="info is-uppercase">
					{{ contact.FirstName }} {{ contact.LastName }}
				</p>
				<p v-if="contact.Account"
				   id="otherInfo"
				   style="font-family: 'Lato'"
				   class="info">
					{{ contact.Account.Name }}
				</p>
				<p v-if="contact.Account"
				   id="otherInfo"
				   style="font-family: 'Lato'"
				   class="info">
					{{ contact.Account.Country }}
				</p>
			</div>
			<div class="column is-3">
				<div class="level-right" v-if="contact.PhotoSrc">
					<img id="personalPhoto" :src="$APIURL + contact.PhotoSrc" style="max-height: 90px">
				</div>
				<div class="level-right" v-if="!contact.PhotoSrc">
					<img id="personalPhoto" src="@/assets/logos/silhouette.jpg" style="max-height: 90px" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "contactResult",
		props: {
			contact: {}
		},
		data() {
			return {
			}
		}
	}
</script>

<style>
	#contactResult {
		background-color: white !important;
		border-color: #dbdbdb !important;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.3) !important;
		border: none !important;
		transition: box-shadow linear .2s !important;
		padding: 15px !important;
	}

		#contactResult:hover {
			box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4), 0 12px 40px 0 rgba(0, 0, 0, 0.3) !important;
		}

	#otherInfo {
		font-size: 13px;
	}

	#contactResult p {
		margin: 2px;
	}

	.info {
		text-align: left;
	}
</style>
