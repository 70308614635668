<template>
	<div class="section">
		<div class="container" id="registerForm">
			<h1>AESC Member Registration</h1>
			<b-message v-if="!valid">
				<b-field label="Please Enter your email from an AESC Member Firm.">
					<b-input required @keydown.native="catch_Enter($event)" v-model="email"></b-input>
				</b-field>
				<b-button class="red-button" :class="{'is-loading': loading}" @click="checkMember()">
					Register
				</b-button>
			</b-message>

			<b-message v-if="valid">
				Please enter your name and select your office:
				<b-field label="First Name">
					<b-input v-model="form.FirstName" type="text" required></b-input>
				</b-field>
				<b-field label="Last Name">
					<b-input v-model="form.LastName" required></b-input>
				</b-field>
				<b-field label="Title">
					<b-input v-model="form.Title" required></b-input>
				</b-field>
				<b-field label="Individual Type">
					<b-select placeholder="Select One" v-model="form.Type" required>
						<option value="Consultant">Consultant</option>
						<option value="Researcher">Researcher</option>
						<option value="Senior Non-Search Staff">Senior Non-Search Staff</option>
						<option value="Administrator">Administrator</option>
					</b-select>
				</b-field>
				<b-field label="Services (Please choose up to six options)" v-show="showServices">
					<b-taginput v-model="form.Services"
								autocomplete
								ellipsis
								type="is-info"
								:allow-new="false"
								:data="filteredServices"
								:open-on-focus="openOnFocus"
								@typing="filterServices"
								placeholder="Add Service">
					</b-taginput>
				</b-field>
				<label style="margin-bottom: 5px;"><b>Your Office</b></label>
				<b-autocomplete required
								v-model="officeTag"
								field="Name"
								:data="filterOffices"
								:open-on-focus="openOnFocus"
								placeholder="Select your Office"
								@select="option => form.Account = option">
				</b-autocomplete>
				<br>
				If your office does not appear on this list, please contact us for
				assistance <a href="mailto:aesc@aesc.org">aesc@aesc.org</a>.
				<br />
				<br />
				<b-checkbox v-model="form.TermsAndConditions" required></b-checkbox>
				I Accept the <a @click="showTandC">Terms and Conditions</a>
				<br />
				<br />
				<b-button :class="{'is-loading': loading}" @click="register"
						  class="red-button">Complete Registration</b-button>
			</b-message>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	export default {
		name: "Register",
		data() {
			return {
				form: {},
				email: '',
				valid: false,
				filteredServices: [],
				services: [],
				openOnFocus: true,
				firmIds: [],
				firmInfo: [],
				offices: [],
				officeTag: '',
				loading: false,
			};
		},
		async created() {
			await axios
				.get("api/services/tags")
				.then(resp => {
					this.services = resp.data;
				})
				.catch(err => {
					this.error = err;
				});
			this.filteredServices = this.services;
		},
		methods: {
			catch_Enter(e) {
				if (e.keyCode === 13 && !this.loading) {
					this.checkMember();
				}
			},
			async register() {

				if (this.showServices == false) {
					this.form.Services = null;
				} else {
					if (this.form.Services != null && this.form.Services.length > 0) {
						this.form.Services__c = this.form.Services.join(';');
					} else {
						this.form.Services__c = "";
					}
				}

				if (this.email && this.form.Account && this.form.FirstName && this.form.LastName && this.form.Title && this.form.Type && this.form.TermsAndConditions) {
					this.loading = true;
					this.form.Email = this.email;

					this.form.AccountId = this.form.Account.Id;

					await axios.post("api/security/sendConfirmation", this.form)
						.then(resp => {
							this.loading = false;
							if (resp.data) {
								this.$buefy.modal.open('<div class="modal-card-body"><p>A confirmation email has been sent.</p></div>');
								this.$router.push({ path: "/login" });
							} else {
								this.$buefy.toast.open("There was an error trying to create your account. Please try again later.");
								this.$router.push({ path: "/register" });
							}
						})
						.catch(err => {
							this.loading = false;
							this.toast.open(err);
						});
				} else {
					this.$buefy.toast.open({
						message: "Please fill out all fields",
						type: "is-danger",
						duration: 8000
					})
				}
			},
			async checkMember() {
				this.loading = true;
				await axios.post("api/contacts/checkMember/" + this.email)
					.then(resp => {
						this.loading = false;
						if (resp.data == "Not a Valid Email Address.") {
							this.$buefy.toast.open({
								message: "You need to enter a valid email address.",
								type: "is-danger",
								duration: 8000
							});
						} else if (resp.data == "Not a Member Email." || resp.data == "Not an Acceptable Domain.") {
							this.$buefy.toast.open({
								message: "You need to enter a valid AESC Member Email.",
								type: "is-danger",
								duration: 8000
							});
						} else if (resp.data == "Too Many Tries.") {
							this.$buefy.toast.open({
								message: "You have tried this too many times. Please try again later.",
								type: "is-danger",
								duration: 8000
							});
						} else if (resp.data == "Valid Member") {
							this.tryRegister();
						} else {
							this.$buefy.toast.open({
								message: "Please try again later. An unhandled exception occurred.",
								type: "is-danger",
								duration: 8000
							});
						}
					}).catch(err => {
						this.loading = false;
						console.log(err);
					});
			},
			async tryRegister() {
				await axios
					.get("api/security/tryRegister/" + this.email)
					.then(resp => {
						switch (resp.data.type) {
							case 'member':
								{
									this.$buefy.modal.open('<div class="modal-card-body"> <p> You already have an account. <br /> To access your AESC account visit <a href="https://www.aesc.org/login">www.aesc.org/login</a><br /> <br /> <a href="https://members.aesc.org/account/forgot">Forgot your password?</a> </p > </div>');
									break;
								}
							case 'nonmember':
								{
									this.$buefy.modal.open('<div class="modal-card-body"> <p> This email is not associated with an AESC Firm. <br /> Please contact us for assistance <a href="mailto:aesc@aesc.org">aesc@aesc.org</a>.</p></div>');
									break;
								}
							case 'invalid':
								{
									this.$buefy.toast.open({
										message: "We're sorry, only those with a valid email address at an AESC member firm are eligible to create an account.",
										type: "is-danger",
										duration: 8000
									});
									break;
								}
							case 'GatherInformation':
								{
									this.valid = true;
									this.firmIds = resp.data.FirmIds;
									this.getFirmInfo();
									break;
								}
							case 'error':
								{
									this.$buefy.toast.open({
										message: "An error occurred while trying to register",
										type: "is-danger",
										duration: 8000
									});
									break;
								}
						}
					})
					.catch(err => {
						this.error = err;
					});

			},
			async getFirmInfo() {
				await axios
					.post("api/accounts/info", this.firmIds)
					.then(resp => {
						this.firmInfo = resp.data;
						this.buildOffices();
					})
					.catch(err => {
						this.error = err;
					});
			},
			buildOffices() {
				this.offices = [];
				this.firmInfo.forEach(elem => {
					elem.Offices.forEach(office => {
						this.offices.push(office);
					});
				});
			},
			filterServices(text) {
				this.filteredServices = this.services.filter((option) => {
					if (option) {
						return option
							.toString()
							.toLowerCase()
							.indexOf(text.toLowerCase()) >= 0;
					}
				})
			},
			showTandC() {
				this.$buefy.modal.open('<div class="modal-card-header"> Terms and Conditions </div > <div class="modal-card-body"> The BlueSteps Database is provided free of charge to AESC members in good standing, solely for the purpose of sourcing potential candidates for open searches conducted by AESC member firms. By clicking "I Agree," I confirm that I work for an AESC member firm and will use all information in this database solely for the purpose of identifying potential candidates for an open search or searches. Because BlueSteps customers may choose to update or delete their data at any time, I further agree to remove any downloaded BlueSteps customer data from my database once the search(es) for which the data was used have concluded. </div>');
			}
		},
		computed: {
			showServices() {
				if (this.form.Type != undefined) {
					if (this.form.Type == "Consultant") {
						return true;
					} else {
						return false;
					}
				} else {
					return false;
				}
			},
			filterOffices() {
				return this.offices.filter((option) => {
					return option
						.Name
						.toString()
						.toLowerCase()
						.indexOf(this.officeTag.toLowerCase()) >= 0;
				})
			},
		}
	};
</script>

<style>
	#registerForm {
		color: black;
		padding-left: 20px;
		padding-right: 20px;
	}
</style>
