<template>
	<div id="messaging">
		<div style="background-color: #1F1F1F; color: white; padding: 15px; background-image: linear-gradient(to top right, black, grey);">
			<div class="container">
				<div class="columns">
					<div class="column is-9">
						<h2>WE SHAPE. CONNECT. EDUCATE.</h2>
						<p style="font-size: 14px; font-family: 'Lato'!important;">
							AESC and its members share a deep commitment to the highest quality standards in executive search and leadership consulting–for the benefit of clients and the profession.
							Through collaboration and innovative thinking, we deliver the future of global leadership today.
						</p>
					</div>
					<div class="column">
						<div class="columns" style="border-left: solid 1px #fff; margin-top: -5px;font-family: 'Lato'!important;">
							<div class="column">
								<img style="padding-left: 50px;" src="@/assets/logos/handbookIcon.png">
							</div>
							<div class="column">
								<a class="is-white-link" style="font-size: 14px;" href="/member/membership/aesc-member-handbook">
									My AESC<br /> Member<br /> Handbook
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>

			<div v-show="!noMail">
				<br />
				<p class="title is-2 aesc-title">Inbox</p>
				<br />
				<inbox v-if="list" :mail="mail" @updateMessage="updateMessage"></inbox>
				<div v-if="message">
					<message v-if="!list" :message="message"></message>
				</div>
			</div>
			<div v-show="noMail">
				<br />
				<p class="title is-2 aesc-title">
					No Mail.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
	import inbox from "@/components/messaging/inbox.vue";
	import message from "@/components/messaging/message.vue";
	import axios from "axios";
	export default {
		name: "messaging",
		components: {
			inbox,
			message
		},
		data() {
			return {
				mail: {},
				message: {},
				user: {},
				list: true,
				noMail: false,
				isLoading: false
			}
		},
		async created() {
			this.login = this.$store.getters["security/login"];
			this.isLoading = true;
			this.refreshInbox();
		},
		methods: {
			updateMessage(msg) {
				this.message = msg;
			},
			async refreshInbox() {
				this.isLoading = true;
				await axios.post("api/message/getMail", { ContactId: this.login.user.Id, GetItems: 10 })
					.then(resp => {
						this.mail = resp.data;
						this.isLoading = false;
					})
					.catch(err => {
						this.$buefy.toast.open({
							message: "Error trying to open mail. Please try again later.",
							duration: 8000,
						});
						this.isLoading = false;
						console.log(err);
					});

				if (this.mail && this.mail.length < 1) {
					this.noMail = true;
				}
			}
		}
	}
</script>

<style>
	#messaging {
		font-family: 'Lato' !important;
	}
</style>
