<template>
	<div id="inboxContainer" v-show="mail.length > 0">
		<b-button type="is-danger" @click="deleteMessages" v-if="showDelete">Confirm Delete</b-button>
		<div v-for="msg in mail"
			 :key="msg.Id"
			 class="column is-8">
			<div class="columns" v-if="msg.BSMember">
				<div class="column is-1" style="padding-top: 50px; padding-left: 20px;">
					<b-checkbox v-model="selectedMessages" :native-value="msg.Id" :disabled="!msg.Read"></b-checkbox>
				</div>
				<div class="column is-11" @click="goToMessage(msg)">
					<b-message id="messageCard">
						<div class="level">
							<div class="level-left">
								<b-icon v-show="msg.Read" class="far fa-circle"></b-icon>
								<b-icon v-show="!msg.Read" class="fas fa-circle"></b-icon>
								<span class="title is-5" v-if="msg.Type != 'Client'">{{msg.BSMember.FirstName}} {{msg.BSMember.LastName}}</span>
								<span class="title is-5" v-if="msg.Type == 'Client'">{{msg.ClientMember.FirstName}} {{msg.ClientMember.LastName}}</span>
							</div>
							<div class="level-right">
								{{ msg.Date }}
							</div>
						</div>
						<div class="level">
							<div class="level-left">
								{{ msg.Title }}
							</div>
						</div>
					</b-message>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	export default {
		name: "inbox",
		props: {
			mail: {}
		},
		data() {
			return {
				selectedMessages: [],
			}
		},
		created() {

		},
		methods: {
			async deleteMessages() {
				await axios.post("api/message/softDelete", this.selectedMessages)
					.then(async resp => {
						if (resp.data) {
							this.$buefy.toast.open({
								message: "Successfully Deleted.",
								duration: 8000,
							});
							await this.$parent.refreshInbox();
						}
					}).catch(err => {
						this.$buefy.toast.open({
							message: "An unhandled Exception Occurred. Please try again later.",
							type: "is-danger",
							duration: 8000
						})
					});
			},
			async goToMessage(message) {
				console.log(message);
				this.$parent.list = false;
				this.$emit('updateMessage', message);

				await axios.get("api/message/readMail/" + message.Id)
					.then(resp => {
						console.log("Message Read.");
					}).catch(err => {
						console.log("message not read properly");
					})
			}
		},
		computed: {
			showDelete: function () {
				if (this.selectedMessages.length > 0) {
					return true;
				} else {
					return false;
				}
			}
		}
	}
</script>

<style>
</style>
