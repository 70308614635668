<template>
	<div class="navSignIn">
		<div>
			<b-tooltip position="is-bottom" type="is-black">
				<template v-slot:content>
					AESC Members sign in for
					<br />
					exclusive member resources
				</template>
				<a href="/login" class="link btn-deepblue">Member Sign In</a>
			</b-tooltip>
		</div>
		<!-- <div class="level-item">
					<b-tooltip position="is-bottom" type="is-black">
						<template v-slot:content>
							Find an AESC Member to work
							<br />
							with your company
						</template>
						<a href="/clientregistration/register" class="link">For Organizations</a>
					</b-tooltip>
					<span class="divider">&nbsp;|&nbsp;</span>
					<b-tooltip position="is-bottom" type="is-black">
						<template v-slot:content>
							Submit your resume to AESC's
							<br />
							executive career service
						</template>
						<a
							href="https://www.bluesteps.com/partner/association-of-executive-search-consultants"
							class="link">
							For Candidates
						</a>
					</b-tooltip>
				</div> -->
	</div>
</template>
<script>
	export default {
		data() {
			return {};
		}
	};
</script>
<style>
	.navSignIn {
		font-size: 1rem;
	}
	.navSignIn .divider {
		font-size: 15px;
		margin: 0px 5px;
	}
</style>
